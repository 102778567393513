import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import gaTrack, { GA_TR_SCHEDULING_BILLING_PAGE } from '../../../../../services/gaTrack';
import PaymentFormV2 from './PaymentFormV2';
import './PaymentInformationFormV2.scss';

export const PAYMENT_FORM_NAME = 'scp-payment-information-form';

const PaymentInformationForm = (props) => {
  const { loading } = props;

  useEffect(() => {
    gaTrack(GA_TR_SCHEDULING_BILLING_PAGE);
    window.scrollTo(0, 0);
  }, []);

  if (loading) return null;

  return (
    <div className="scp-schedule-wizard scp-payment-stage">
      <h2 className="gm-select-service__header">Enter Payment Information</h2>
      <PaymentFormV2 {...props} />
    </div>
  );
};

PaymentInformationForm.propTypes = {
  loading: PropTypes.bool,
  newPrice: PropTypes.number,
  initialValues: PropTypes.object,
  onSkip: PropTypes.func,
  toPreviousStep: PropTypes.func,
  insurance: PropTypes.object,
  companies: PropTypes.object,
};

export default PaymentInformationForm;
