import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getConsultation, findCouponItem } from 'ducks/scheduling';
import schedulingService, { GM_HELIX_COUPON_CODE } from 'services/api/schedulingService';
import { formatPrice } from 'services/utils';
import './CouponApplierForm.scss';
import { Input, Button } from 'gm-element-react';

const CouponApplierForm = ({
  couponError,
  clearCoupon,
  scheduling,
  newPrice,
  insurance,
  companies,
  setCouponCode,
  appliedCoupon,
  readOnlyCouponCode,
  isMobile,
}) => {
  const { selectedServiceDescriptor, reservedAppointment, selectedProduct, isCheckingCouponCode } =
    scheduling;

  const [showPromoCode, setShowPromoCode] = useState(false);
  const [code, setCode] = useState('');

  useEffect(() => {
    return () => {
      if (couponError) clearCoupon();
    };
  }, []);

  const renderField = ({ input, meta: { touched, error }, isCouponApplied }) => (
    <div
      className={classnames('gm-form-field coupon-field', {
        applied: isCouponApplied,
        error: touched && error,
      })}
    >
      <div className={classnames('gm-form-control coupon-control')}>
        <input {...input} disabled={isCouponApplied} />
        <i className="fa fa-check" />
      </div>
    </div>
  );

  const isPackage = selectedServiceDescriptor && selectedServiceDescriptor.isPackage;

  const speciality = (() => {
    if (!reservedAppointment) {
      return '';
    }
    return (
      reservedAppointment.vsee_specialty &&
      reservedAppointment.vsee_specialty.replace('_Willing', '').replace('_', ' ')
    );
  })();

  const caption = (() => {
    let caption = '';
    if (isPackage && selectedServiceDescriptor) {
      caption = selectedServiceDescriptor.productName;
    } else {
      const consultation = getConsultation(selectedServiceDescriptor, selectedProduct);
      caption += consultation.name;
    }
    return caption;
  })();

  const price = (() => {
    if (!reservedAppointment) {
      return '';
    }
    if (payWithInsurance) {
      return '$' + formatPrice(insurancePrice);
    }
    const servicePrice = reservedAppointment.consultation && reservedAppointment.consultation.price;
    return '$' + formatPrice(newPrice != null ? newPrice : servicePrice);
  })();

  const visitPrice = (() => {
    if (!reservedAppointment) {
      return '';
    }
    if (payWithInsurance) {
      return '$' + formatPrice(insurancePrice);
    }
    const servicePrice = reservedAppointment.consultation && reservedAppointment.consultation.price;
    return '$' + formatPrice(servicePrice);
  })();

  const insurancePrice = (() => {
    const insuranceCompany = companies.find(
      (c) => c.id === insurance?.primaryInsurance?.insuranceCompanyId
    );

    return insuranceCompany ? insuranceCompany.price : '';
  })();

  const submitCouponCode = () => {
    setCouponCode(code, true);
  };

  const togglePromoCode = (value) => {
    setShowPromoCode(value);
  };

  const getDiscountValue = (() => {
    const coupon = findCouponItem(scheduling);
    let discountValue = coupon?.discountValue;
    const consulationPrice = _.get(reservedAppointment, ['consultation', 'price']);
    if (consulationPrice !== undefined) {
      discountValue = discountValue >= consulationPrice ? consulationPrice : discountValue;
    }
    return '-$' + discountValue;
  })();

  const payWithInsurance = !_.isEmpty(insurance) && schedulingService.getUseInsurance();
  const helixCouponApplied = appliedCoupon && appliedCoupon.code === GM_HELIX_COUPON_CODE;
  const haveError = !isCheckingCouponCode && couponError && code;
  const isApplied = !isCheckingCouponCode && appliedCoupon && !helixCouponApplied;
  const showPromoCodeForm = !payWithInsurance && showPromoCode && !isApplied;
  const showPromoCodeText = !payWithInsurance && !showPromoCode && !isApplied;
  return (
    <div
      className={classnames('scp-coupon-applier v2', {
        ismobile: isMobile,
      })}
    >
      {!isMobile && <div className="code-summary">Summary</div>}

      {!isMobile && (
        <div className="visit-container" data-hj-suppress>
          <div className="coupon-service-name">{caption}</div>
          <div className="speciality-name">{speciality}</div>
        </div>
      )}

      <div className="coupon-container">
        <div className="total-block">
          <div className="visit-deposit-block">
            <div className="price-label">
              <span className="visit-deposit-label">
                {' '}
                {payWithInsurance ? 'Visit Deposit' : 'Visit fee'}{' '}
              </span>
              {showPromoCodeText && (
                <div className="promo-code-label" onClick={() => togglePromoCode(true)}>
                  Enter Promo Code{' '}
                </div>
              )}
            </div>
            <span className={`${isApplied ? 'total-values' : 'price-value'}`}>{visitPrice}</span>
          </div>
        </div>
        {showPromoCodeForm && !isApplied && (
          <Fragment>
            <div className="form-row">
              <div className="form-col">
                <div className="label-section required">Promo Code</div>
                <div className="promo-code-input">
                  <div className={haveError ? 'error' : ' promo-input-text'}>
                    <Input
                      type={'text'}
                      placeholder={'1234 5678 9012 3456'}
                      value={code}
                      name="code"
                      onChange={(value) => {
                        setCode(value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          submitCouponCode();
                        }
                      }}
                    />
                    <div className="input-error">
                      {haveError ? couponError.fullMessage || "Can't apply this coupon code" : ''}
                    </div>
                  </div>

                  <Button
                    type="button"
                    className="coupn-apply-btn"
                    disabled={_.isEmpty(code)}
                    onClick={() => submitCouponCode()}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </Fragment>
        )}
        <div className="coupon-field-block">
          {isApplied && (
            <div className="coupon-applied-block">
              <div className="coupon-label-applied">
                <div className="coupon-label-code">{appliedCoupon.code}</div>
                {!readOnlyCouponCode && (
                  <div className="gm-link undo-coupon" onClick={clearCoupon}>
                    Remove
                  </div>
                )}
              </div>
              <div className="coupon-applied-code">{getDiscountValue}</div>
            </div>
          )}
        </div>
        {isApplied && (
          <div className="total-block pad-bt-24">
            <span className="price-label">Visit Fee Total </span>
            <span className="price-value">{price}</span>
          </div>
        )}
      </div>
    </div>
  );
};

CouponApplierForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isCheckingCouponCode: PropTypes.bool,
  validCoupon: PropTypes.object,
  invalidCoupon: PropTypes.object,
  couponError: PropTypes.object,
  clearCoupon: PropTypes.func.isRequired,
  scheduling: PropTypes.object,
  newPrice: PropTypes.number,
  setCouponCode: PropTypes.func.isRequired,
  appliedCoupon: PropTypes.object,
  insurance: PropTypes.object,
  companies: PropTypes.array,
};

const mapStateToProps = (state) => {
  let code = (state.scheduling.appliedCoupon && state.scheduling.appliedCoupon.code) || '';
  if (code === GM_HELIX_COUPON_CODE) code = '';

  return {
    scheduling: state.scheduling,
  };
};

export default connect(mapStateToProps)(CouponApplierForm);
