import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import track from 'services/track';
import classnames from 'classnames';
import AppointmentServiceOptions from '../../../../../components/scp/scheduling/AppointmentDetails/AppointmentOptions/AppointmentServiceOptionsV2';
import _ from 'lodash';
import Button from 'components/forms/controls/ButtonV2';
import SelectSpeciality from './SelectSpeciality';
import { selectDefaultSpecialityByUser, selectSpecialityByUser } from 'ducks/scheduling';

const SelectServicePanel = ({
  dispatch,
  setSelected,
  type,
  setIsSpecialitySelected,
  onPurchaseClick,
  path,
  selected,
  user,
  header,
  description,
  iconClassName,
  isMobile,
  isSpecialtySelected,
  key,
}) => {
  const [showSpeciality, setShowSpeciality] = useState(false);
  const [tempSpeciality, setTempSepciality] = useState('');
  const [previousSelectedSpeciality, setPreviousSelectedSpeciality] = useState('');

  useEffect(() => {
    if (selected == type) {
      const { me } = user;
      const { partnerSpecialties } = me;
      setIsSpecialitySelected(false);
      let selectedSpecialty;
      let defaultCalendarSpeciality = _.get(user, 'me.vsee_specialty', null);
      if (partnerSpecialties.length === 1) {
        selectedSpecialty = partnerSpecialties[0].name;
        setIsSpecialitySelected(selected);
      } else if (
        (partnerSpecialties || []).find((e) => e && e.name && e.name == defaultCalendarSpeciality)
      ) {
        selectedSpecialty = defaultCalendarSpeciality;
        setIsSpecialitySelected(selected);
      }
      dispatch(selectDefaultSpecialityByUser(selectedSpecialty));
      handleShowSpeciality(false);
      setTempSepciality('');
    }
  }, [selected]);

  const onClick = () => {
    setSelected(type);
  };

  const onServiceClick = (header) => {
    if (header) track('Clicked on ' + header);
    return onClick();
  };

  const handleShowSpeciality = (value) => {
    setShowSpeciality(value);
    setPreviousSelectedSpeciality(value ? tempSpeciality : null);
  };

  const formatPrice = (price) => {
    return isNaN(price)
      ? price
      : new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(price);
  };

  const onFinalPurchaseClick = () => {
    onPurchaseClick(path);
  };

  const closeAppointmentServiceOption = () => {
    setSelected(null);
  };

  const handleSpecilitiesNext = () => {
    setIsSpecialitySelected(selected);
    dispatch(selectSpecialityByUser(tempSpeciality));
    handleShowSpeciality(false);
  };

  const handleSpecilitiesBack = () => {
    setTempSepciality(previousSelectedSpeciality);
    handleShowSpeciality(false);
  };

  const { me } = user;
  const { partnerSpecialties, vsee_specialty } = me;
  let selectedSpecialty;
  if (tempSpeciality) {
    selectedSpecialty = tempSpeciality;
  } else {
    let defaultCalendarSpeciality = vsee_specialty;
    if (partnerSpecialties.length === 1) {
      selectedSpecialty = partnerSpecialties[0].name;
    } else if (
      (partnerSpecialties || []).find((e) => e && e.name && e.name === defaultCalendarSpeciality)
    ) {
      selectedSpecialty = defaultCalendarSpeciality;
    }
  }
  const showAppointmentOptions = type === selected;

  return (
    <div className="panel-container">
      {showAppointmentOptions && showSpeciality ? (
        <SelectSpeciality
          iconClassName={iconClassName}
          isMobile={isMobile}
          partnerSpecialties={partnerSpecialties}
          selectedSpecialty={selectedSpecialty}
          setTempSepciality={setTempSepciality}
          handleSpecilitiesBack={handleSpecilitiesBack}
          handleSpecilitiesNext={handleSpecilitiesNext}
          tempSpeciality={tempSpeciality}
        />
      ) : (
        <div
          className={classnames('selector-panel v2', { unselected: !showAppointmentOptions })}
          onClick={() => {
            return !showAppointmentOptions ? onServiceClick(header) : () => {};
          }}
        >
          <div className="service-content">
            {!_.isEmpty(iconClassName) && ((isMobile && !showAppointmentOptions) || !isMobile) ? (
              <div
                className={classnames(
                  'icon-contain',
                  iconClassName === 'icon-follow-up' ? 'has-follow-up' : ''
                )}
              >
                <div className={classnames('icon-section', iconClassName)} />
              </div>
            ) : (
              ''
            )}
            <div className="name-section">
              <h4
                className={classnames('service-name', {
                  'service-name__no-icon': !iconClassName,
                })}
              >
                {header}
              </h4>
              {!_.isEmpty(description) ? (
                <div className="service-description v2">{description || ''}</div>
              ) : (
                ''
              )}
            </div>
          </div>
          {showAppointmentOptions ? (
            <div className="appointment-content">
              <AppointmentServiceOptions
                header={header}
                type={type}
                onFinalPurchaseClick={onFinalPurchaseClick}
                path={path}
                isArrowVisible={false}
                selected={selected}
                showAppointmentOptions={showAppointmentOptions}
                key={'appointment_service_option' + key}
                isMobile={isMobile}
                setShowSpeciality={handleShowSpeciality}
                isSpecialtySelected={isSpecialtySelected}
                selectedSpecialty={selectedSpecialty}
              />
            </div>
          ) : null}
        </div>
      )}
      {isMobile && showAppointmentOptions && (
        <div className="outer-button outline">
          <Button
            className=""
            variant="secondary"
            onClick={() => {
              showSpeciality ? handleSpecilitiesBack() : closeAppointmentServiceOption();
            }}
          >
            {'Back'}
          </Button>
        </div>
      )}
    </div>
  );
};

SelectServicePanel.propTypes = {
  header: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  oldPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  path: PropTypes.string.isRequired,
  iconClassName: PropTypes.string,
  description: PropTypes.string,
  features: PropTypes.array,
  disabled: PropTypes.bool,
  onPurchaseClick: PropTypes.func.isRequired,
  insuranceEnabled: PropTypes.bool,
  is_payment_mode_insurance_only: PropTypes.bool,
  selected: PropTypes.string,
  preSelected: PropTypes.string,
  zeroPriceText: PropTypes.string,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  scheduling: state.scheduling,
  user: state.user,
});

SelectServicePanel.defaultProps = {
  isMobile: false,
};

export default connect(mapStateToProps)(SelectServicePanel);
