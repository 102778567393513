import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initialize as initializeForm } from 'redux-form';
import AppointmentDetails from 'components/scp/scheduling/AppointmentDetails/AppointmentDetails';
import PaymentInformationForm, {
  PAYMENT_FORM_NAME,
} from 'components/scp/forms/WizardForm/PaymentInformationForm/PaymentInformationForm';
import ReservationTimer from 'components/scheduling/ReservationTimer/ReservationTimer';
import { getHardcodedDescriptorByType } from 'constants/ServiceTypes';
import modalNames from 'constants/ModalNames';
import { setModalMode } from 'ducks/modals';
import {
  reserveAppointment,
  loadSchedulingIfNeeded,
  checkSavedCouponCode,
  setSelectedService,
  resetOutreachAppointment,
  resetExceptionalSpecialty,
  setPayWithInsurance,
} from 'ducks/scheduling';
import { withCouponApplier } from 'components/high-order-components/CouponApplier/CouponApplier';
import { clearDeletingError } from 'ducks/appointment';
import schedulingService from 'services/api/schedulingService';
import {
  loadPaymentMethod,
  loadInsuranceCompanies,
  loadInsurance,
  loadAllAddress,
  SetupStripeIntent,
} from 'ducks/user';
import { getPlanTypesEnums } from 'ducks/enums';
import {
  setStage,
  submitPaymentInfo,
  createAddress,
  updateAddress,
  submitInsurance,
  bookAppointment,
  setPaymentby,
} from 'ducks/registrationWizard';
import commonScheduling, {
  scpScheduling,
  STAGE_SELECT_SERVICE,
  STAGE_APPOINTMENT_DETAILS,
  STAGE_PAYMENT_INFO,
  STAGE_INSURANCE_INFO,
  STAGE_APPOINTMENT_CONFIRM,
  STAGE_SHIPPING_ADDRESS,
  STAGE_REVIEW_PAYMENT,
} from 'services/wizardWorkflows';
import {
  PAYMENTBYINSURANCE,
  PAYMENTBYSELFPAY,
  invalidInsuranceCompanyName,
} from '../../../../../constants/CommonConstants';
import ConfirmAppointmentForm from 'components/scp/forms/WizardForm/ConfirmAppointmentForm/ConfirmAppointmentForm';
import InsuranceInformationFormV2 from 'components/scp/forms/WizardForm/InsuranceInformationForm/InsuranceInformationFormV2';
import BreadcrumbProgress from 'components/scp/registration/BreadcrumbProgress';
import './RegistrationWizardPage.scss';
import track, {
  TR_BILLING_INFO_SUBMITTED,
  TR_HOME_ADDRESS_SUBMITTED,
  TR_APPOINTMENT_CONFIRMATION_SUBMITTED,
  TR_SHIPPING_ADDRESS_SUBMITTED,
} from 'services/track';
import {
  Cigna_GenericReferral,
  Kaiser_NWReferral,
  CIGNA_INSURANCE,
  KAISER_INSURANCE,
} from '../../../../../services/misc/ReferralSource';
import { documentTypesNames, INS_FRONT_CARD, INS_BACK_CARD } from 'constants/DocumentsConstants';
import {
  uploadDocumentInsuranceCard,
  loadDocuments,
  loadDocumentTypes,
  resetnsuranceCardFront,
  resetInsuranceCardBack,
} from 'ducks/documents';
import {
  CreateFileNameForInsuranceCard,
  HOME,
  SHIPPING,
  getHomeAddress,
  getShippingAddress,
  getBillingAddress,
} from 'services/utils';
import { showLoader, closeLoader } from 'ducks/ui';
import { loadCurrentSCPEncounter } from 'ducks/scpencounters';
import classnames from 'classnames';
import { logout } from 'ducks/auth/email';
import SelectServicePageV2 from '../SelectServicePage/SelectServicePageV2';
import ReviewPaymentSummaryForm from '../../../../../components/scp/forms/WizardForm/ReviewPaymentSummaryForm/ReviewPaymentSummaryForm';
import ShippingAddressPage from '../../../../../components/scp/forms/WizardForm/ShippingAddressPage/ShippingAddressPage';
import NoConsultationErrorScreen from '../NoConsultationErrorScreen/NoConsultationErrorScreen';
import { withRouter } from 'react-router';

class SchedulingWizardPage extends Component {
  state = { isMobile: false };
  static updateSelectedService(props) {
    if (!props.selectedService) return;
    const { scheduling, selectedService, setSelectedService } = props;
    const currentService = scheduling.selectedServiceDescriptor;
    if (scheduling.isSchedulingLoaded && !currentService) setSelectedService(selectedService);
  }

  static setRegistrationStage(props) {
    const { dispatch, selectedStage, registrationWizard } = props;
    const { activeStage } = registrationWizard;
    if (activeStage !== selectedStage) dispatch(setStage(selectedStage));
  }

  componentDidMount() {
    this.setState({ isMobile: schedulingService.isMobile() });
    window.addEventListener('resize', this.onResize);
    this.props.loadSchedulingIfNeeded().then(() => this.checkRedirectAndStage(this.props));
    if (this.props.needLoadPaymentMethod) this.props.loadPaymentMethod();
    if (_.isEmpty(this.props.insuranceCompanies)) this.props.loadInsuranceCompanies();
    if (_.isEmpty(this.props.insurance)) this.props.loadInsurance();
    if (_.isEmpty(this.props.allAddress)) this.props.loadAllAddress();
    if (_.isEmpty(this.props.planTypesEnums)) this.props.getPlanTypesEnums();
  }

  componentWillUnmount() {
    this.props.dispatch(resetOutreachAppointment());
    this.props.dispatch(resetExceptionalSpecialty());
    this.props.dispatch(setPayWithInsurance(null));
    window.removeEventListener('resize', this.onResize);
  }

  shouldComponentUpdate(nextProps) {
    const { scheduling, user, appointmentLoading } = nextProps;
    return (
      !scheduling.isSchedulingLoading &&
      !scheduling.isCheckingCouponCode &&
      !user.meLoading &&
      !user.paymentMethodLoading &&
      !appointmentLoading
    );
  }

  componentDidUpdate() {
    const {
      match: { params },
      history,
    } = this.props;
    if (!commonScheduling.find((s) => s.path === params.step)) {
      return history.replace('/scheduling/select-service');
    }
    this.checkRedirectAfterUpdate();
    this.checkRedirectAndStage();
    const { scheduling, needLoadPaymentMethod } = this.props;
    if (!scheduling.isSchedulingLoaded && !scheduling.isSchedulingLoading) {
      this.props.loadSchedulingIfNeeded();
    }
    if (!this.consultationTypesIsSet && !_.isEmpty(scheduling.consultationTypes)) {
      this.consultationTypesIsSet = true;
      this.checkCoupon();
    }
    if (needLoadPaymentMethod) this.props.loadPaymentMethod();
  }

  consultationTypesIsSet = false;

  checkCoupon() {
    if (!this.props.scheduling.appliedCoupon) this.props.dispatch(checkSavedCouponCode());
  }

  checkRedirect() {
    const { selectedStage, appointment, history } = this.props;

    // Don't allow to schedule appointment if it's already booked
    if (
      !_.isEmpty(appointment) &&
      appointment.isUpcoming &&
      appointment.latestStatus &&
      appointment.latestStatus.status === 'booked'
    ) {
      history.push('/patient');
      return true;
    }

    // if only service is available, go to calendar
    /*if (selectedStage === STAGE_SELECT_SERVICE && this.onlyServiceNameOrType) {
      this.props.gotoCalendar(this.onlyServiceNameOrType);
    }*/

    // Payment and confirm not permitted until appointment is reserved
    if (
      !schedulingService.getBookedAppointmentId() &&
      !schedulingService.getReservedAppointment() &&
      (selectedStage === STAGE_PAYMENT_INFO || selectedStage === STAGE_APPOINTMENT_CONFIRM)
    ) {
      this.props.gotoSelectService();
      return true;
    }

    return false;
  }

  checkRedirectAfterUpdate() {
    // if selected product is Null (wrong service selected in URL)
    // then redirect user to service selection, and clear any preselected service
    // we don't replace URL because we don't want some strange state
    // This must be called only after authorization and loading of actual consultations info
    const {
      scheduling: { selectedServiceDescriptor, selectedProduct },
      registrationWizard: { activeStage },
      history,
    } = this.props;
    if (
      (selectedServiceDescriptor && !selectedProduct) ||
      (!selectedServiceDescriptor && selectedProduct)
    ) {
      window.location = '/scheduling/select-service';
    }
    if (
      (activeStage === STAGE_APPOINTMENT_DETAILS ||
        activeStage === STAGE_PAYMENT_INFO ||
        activeStage === STAGE_APPOINTMENT_CONFIRM) &&
      !selectedServiceDescriptor
    ) {
      history.push('/scheduling/select-service');
    }
  }

  checkRedirectAndStage() {
    if (!this.checkRedirect()) {
      SchedulingWizardPage.setRegistrationStage(this.props);
      SchedulingWizardPage.updateSelectedService(this.props);
    }
  }

  submitPaymentInformation = (values) => {
    const { dispatch } = this.props;
    const { billingSameAs, addresses } = values;
    return createAddress(addresses, dispatch).then((data) => {
      track(TR_HOME_ADDRESS_SUBMITTED);
      let type = HOME;
      let obj = (data || []).find((el) => el && el.addressType && el.addressType.includes(type));
      return obj;
    });
  };
  handlePaymentMethod = (data) => {
    const { gotoConfirmAppointment, dispatch } = this.props;
    return submitPaymentInfo({ ...data }, dispatch).then((res) => {
      track(TR_BILLING_INFO_SUBMITTED);
      this.gotoPaymentReview();
      return res;
    });
  };

  submitHomeAddressInfo = (values) => {
    const { dispatch, homeAddress, gotoSelectService } = this.props;
    if (homeAddress && homeAddress.addressId) {
      return updateAddress(values, dispatch).then(() => {
        track(TR_HOME_ADDRESS_SUBMITTED);
        gotoSelectService();
      });
    } else {
      return createAddress(values, dispatch).then(() => {
        track(TR_HOME_ADDRESS_SUBMITTED);
        gotoSelectService();
      });
    }
  };

  redirectAfterCalender = () => {
    if (
      !_.isEmpty(this.props.insuranceCompanies) &&
      (this.props.needPaymentInfo || this.zeroConsultationShowInsurance) &&
      this.props.scheduling.selectedProduct &&
      this.props.scheduling.selectedProduct.isServicePackage != true
    ) {
      this.props.gotoInsuranceInfo();
    } else if (this.props.needPaymentInfo) {
      this.props.gotoPaymentInfo();
    } else {
      this.gotoPaymentReview();
    }
  };

  submitInsuranceInformation = (values, frontFileData, backFileData, addresses) => {
    const { documentTypes, user } = this.props;
    const docType =
      documentTypes &&
      _.find(documentTypes, function (doc) {
        return doc && doc.name.toLowerCase() == documentTypesNames.insuranceCard.toLowerCase();
      });
    const promiseall = [];
    if (frontFileData && frontFileData.length > 0) {
      const filename = frontFileData[0].name || 'undefined.jpg';
      const ext = filename.substring(filename.lastIndexOf('.'), filename.length) || '';
      const newname = CreateFileNameForInsuranceCard(
        docType,
        _.get(user, 'me.firstName', ''),
        _.get(user, 'me.lastName', ''),
        ext,
        INS_FRONT_CARD
      );
      promiseall.push(
        this.props.dispatch(
          uploadDocumentInsuranceCard(frontFileData[0], docType, newname, INS_FRONT_CARD)
        )
      );
    }

    if (backFileData && backFileData.length > 0) {
      const filename = backFileData[0].name || 'undefined.jpg';
      const ext = filename.substring(filename.lastIndexOf('.'), filename.length) || '';
      const newname = CreateFileNameForInsuranceCard(
        docType,
        _.get(user, 'me.firstName', ''),
        _.get(user, 'me.lastName', ''),
        ext,
        INS_BACK_CARD
      );
      promiseall.push(
        this.props.dispatch(
          uploadDocumentInsuranceCard(backFileData[0], docType, newname, INS_BACK_CARD)
        )
      );
    }

    this.handlePayWithInsurance(values.payWithInsurance);

    const submitPromise = values.payWithInsurance
      ? this.props.dispatch(submitInsurance(values))
      : Promise.resolve();
    promiseall.push(submitPromise);
    if (values.payWithInsurance) {
      promiseall.push(updateAddress(addresses, this.props.dispatch));
    }

    this.props.dispatch(showLoader());
    return Promise.all(promiseall)
      .then(() => {
        this.props.dispatch(closeLoader());
        if (values.payWithInsurance) {
          this.props.clearCoupon();
          this.props.dispatch(setPaymentby(PAYMENTBYINSURANCE));
        } else this.props.dispatch(setPaymentby(PAYMENTBYSELFPAY));
        if (
          (this.props.needPaymentInfo ||
            (this.getUseInsurance && this.zeroConsultationShowInsurance)) &&
          !(values.payWithInsurance && this.insuranceZeroPrice)
        ) {
          return this.props.gotoPaymentInfo();
        } else {
          return this.gotoPaymentReview();
        }
      })
      .catch((error) => {
        this.props.dispatch(closeLoader());
        if (error.submissionError) throw error.submissionError;
        throw error;
      });
  };

  submitShippingAddressInformation = (values) => {
    const { dispatch, shippingAddress, gotoConfirmAppointment } = this.props;
    if (shippingAddress && shippingAddress.addressId) {
      return updateAddress(values, dispatch).then(() => {
        track(TR_SHIPPING_ADDRESS_SUBMITTED);
        gotoConfirmAppointment();
      });
    } else {
      return createAddress(values, dispatch).then(() => {
        track(TR_SHIPPING_ADDRESS_SUBMITTED);
        gotoConfirmAppointment();
      });
    }
  };

  submitConfirmationApp = (values) => {
    return this.props.dispatch(bookAppointment(values)).then(() => {
      track(TR_APPOINTMENT_CONFIRMATION_SUBMITTED);
      this.props.dispatch(loadCurrentSCPEncounter());
    });
  };

  openCancelAppointmentModal = () => {
    this.props.dispatch(clearDeletingError());
    this.props.openCancelAppointment();
  };

  onAppointmentReserved = () => {
    this.redirectAfterCalender();
  };

  reserveAppointment = () => {
    const { dispatch } = this.props;
    return dispatch(reserveAppointment());
  };

  get onlyServiceNameOrType() {
    const {
      scheduling: { purchasedServicePackages, consultationTypes },
    } = this.props;
    if (consultationTypes.length !== 1) return null;
    if (!_.isEmpty(purchasedServicePackages)) {
      return purchasedServicePackages[0].alias;
    } else {
      const hardcodedConsultation = getHardcodedDescriptorByType(consultationTypes[0].type);
      return hardcodedConsultation ? hardcodedConsultation.urlParameter : consultationTypes[0].type;
    }
  }

  get selectedConsultationIsFree() {
    return this.props.needPaymentInfo === false;
  }

  get allConsultationsIsFree() {
    const {
      scheduling: { consultationTypes },
    } = this.props;
    return !consultationTypes.find((c) => c.price > 0.0);
  }

  get insuranceZeroPrice() {
    const { insurance, insuranceCompanies } = this.props;
    if (insurance && !_.isNil(insurance?.primaryInsurance?.insuranceCompanyId)) {
      const company = insuranceCompanies.find(
        (c) => c.id === insurance?.primaryInsurance?.insuranceCompanyId
      );
      return !_.isNil(company) && company.price === 0.0;
    } else return false;
  }

  get onBack() {
    const {
      gotoCalendar,
      gotoInsuranceInfo,
      gotoPaymentInfo,
      insuranceCompanies,
      needPaymentInfo,
      gotoSelectService,
      registrationWizard: { activeStage },
      scheduling: { selectedProduct },
      user,
    } = this.props;
    const gotoCalendarOrInsurance =
      !_.isEmpty(insuranceCompanies) &&
      (needPaymentInfo || this.zeroConsultationShowInsurance) &&
      selectedProduct &&
      selectedProduct.isServicePackage != true
        ? gotoInsuranceInfo
        : gotoCalendar;

    const requireShippingAddress = _.get(user, ['me', 'requireShippingAddress'], false);

    switch (activeStage) {
      case STAGE_APPOINTMENT_DETAILS:
        if (!this.isPreSelectedFlow()) {
          return () => gotoSelectService();
        }
        break;
      case STAGE_INSURANCE_INFO:
        return () => gotoCalendar();
      case STAGE_PAYMENT_INFO:
        return () => gotoCalendarOrInsurance();
      case STAGE_REVIEW_PAYMENT:
        return (needPaymentInfo || (this.getUseInsurance && this.zeroConsultationShowInsurance)) &&
          !this.insuranceZeroPrice
          ? () => gotoPaymentInfo()
          : () => gotoCalendarOrInsurance();
      case STAGE_SHIPPING_ADDRESS:
        return () => this.gotoPaymentReview();
      case STAGE_APPOINTMENT_CONFIRM:
        return requireShippingAddress
          ? () => this.gotoShippingAddress()
          : () => this.gotoPaymentReview();
    }
    return null;
  }

  get insurancePrice() {
    const { insurance, insuranceCompanies } = this.props;
    let insuranceCompany = null;
    if (!_.isEmpty(insuranceCompanies)) {
      insuranceCompany = insuranceCompanies.find(
        (c) => c.id === insurance?.primaryInsurance?.insuranceCompanyId
      );
    }
    return insuranceCompany ? insuranceCompany.price : '';
  }

  get zeroConsultationShowInsurance() {
    const { insuranceCompanies, priceIsZero } = this.props;
    return priceIsZero && !_.isEmpty(insuranceCompanies);
  }

  get getUseInsurance() {
    return schedulingService.getUseInsurance();
  }

  get workflow() {
    const {
      insuranceCompanies,
      needPaymentInfo,
      scheduling: { selectedProduct },
      user,
    } = this.props;
    let workflow = scpScheduling;
    const requireShippingAddress = _.get(user, ['me', 'requireShippingAddress'], false);

    const stubStages = (stageConditions) =>
      workflow.filter(
        ({ id }) => !stageConditions.find(({ stage, condition }) => condition && id === stage)
      );

    workflow = stubStages([
      { stage: STAGE_SELECT_SERVICE, condition: this.onlyConsultationIsAvailable },
      {
        stage: STAGE_PAYMENT_INFO,
        condition: !(needPaymentInfo || (this.getUseInsurance && this.insurancePrice)),
      },
      {
        stage: STAGE_INSURANCE_INFO,
        condition:
          _.isEmpty(insuranceCompanies) ||
          !(needPaymentInfo || this.zeroConsultationShowInsurance) ||
          selectedProduct.isServicePackage == true,
      },
    ]);

    workflow = !requireShippingAddress
      ? workflow.filter((o) => o.id !== 'STAGE_SHIPPING_ADDRESS')
      : workflow;

    return workflow;
  }

  isProduction() {
    return process.env.GM_ENV == 'production';
  }

  getSelectedSaveAs = () => {
    const { billingAddress, homeAddress } = this.props;
    let addressType = [];
    if (!_.isEmpty(billingAddress)) {
      addressType = _.get(billingAddress, 'addressType', []);
    } else {
      addressType = _.get(homeAddress, 'addressType', []);
    }
    if (addressType.includes(HOME) && addressType.includes(SHIPPING)) {
      return 'home-shipping';
    } else if (addressType.includes(HOME)) {
      return 'home';
    } else if (addressType.includes(SHIPPING)) {
      return 'shipping';
    } else {
      return 'billing';
    }
  };

  onResize = () => {
    this.setViewMode();
  };

  setViewMode() {
    const { isMobile } = this.state;
    if (isMobile && !schedulingService.isMobile()) {
      this.setState({ isMobile: false });
    } else if (!isMobile && schedulingService.isMobile()) {
      this.setState({ isMobile: true });
    }
  }

  onLogout = () => {
    this.props.logout();
  };

  handlePayWithInsurance = (value) => {
    this.props.dispatch(setPayWithInsurance(value));
  };

  closeModal = (name) => {
    this.props.dispatch(setModalMode(name, { open: false }));
  };

  isPreSelectedFlow = () => {
    const {
      scheduling: { consultationTypes },
      user: {
        me: { partnerSpecialties },
      },
    } = this.props;
    const { isOutreachAppointmentFlow } = schedulingService.getOutreachAppointmentData();
    return (
      isOutreachAppointmentFlow || (consultationTypes.length == 1 && partnerSpecialties.length == 1)
    );
  };

  ShippingSameAsHome = () => {
    const { shippingAddress } = this.props;
    if (!_.isEmpty(shippingAddress)) {
      const addressType = _.get(shippingAddress, 'addressType', []);
      if (addressType.includes(HOME)) return true;
    }
    return false;
  };

  gotoPaymentReview = () => {
    const { history,selectedService } = this.props;
    history.push(`/scheduling/${selectedService}/payment-review`);
  };

  gotoShippingAddress = () => {
    const { history,selectedService } = this.props;
    history.push(`/scheduling/${selectedService}/shipping-address`);
  };

  render() {
    const {
      user,
      scheduling,
      paymentMethod,
      newPrice,
      appliedCoupon,
      hiddenCouponApplied,
      gotoConfirmAppointment,
      setCouponCode,
      clearCoupon,
      couponError,
      insurance,
      allAddress,
      allAddressLoaded,
      homeAddress,
      shippingAddress,
      billingAddress,
      insuranceCompanies,
      isInsuranceDisable,
      preventInsuranceCompanies,
      gotoCalendar,
      readOnlyCouponCode,
      location,
      planTypesEnums,
      modals,
      CountriesStateEnums,
      getStripeIntent,
      gotoSelectService,
      history,
    } = this.props;

    const couponApplierProps = {
      appliedCoupon,
      hiddenCouponApplied,
      newPrice,
      setCouponCode,
      clearCoupon,
      couponError,
      readOnlyCouponCode,
    };

    const { formError, activeStage, paymentby, bookAppointmentTimedOutError } =
      this.props.registrationWizard;
    const {
      selectedServiceDescriptor,
      reservedAppointment,
      payWithInsuranceState,
      consultationTypes,
      isSchedulingLoaded,
    } = scheduling;
    const isPackage =
      scheduling && selectedServiceDescriptor && selectedServiceDescriptor.isPackage;

    const servicePackageName =
      isPackage && scheduling.selectedProduct ? scheduling.selectedProduct.name : '';

    const extClientData = user.me;
    const needTimer =
      (activeStage === STAGE_PAYMENT_INFO || activeStage === STAGE_APPOINTMENT_CONFIRM) &&
      schedulingService.getReservationTime();

    const insuranceEnabled = !_.isEmpty(insuranceCompanies);
    const is_payment_mode_insurance_only =
      user && user.me && user.me.is_payment_mode_insurance_only;

    const patients_biological_sex = user && user.me && user.me.gender;

    const defaultPaymentMode = schedulingService.getDefaultPaymentMode();
    let payWithInsurance =
      (is_payment_mode_insurance_only && is_payment_mode_insurance_only == true) ||
      (defaultPaymentMode == 'insurance' && insuranceEnabled)
        ? true
        : defaultPaymentMode == 'self-pay' && !is_payment_mode_insurance_only
        ? false
        : true;

    payWithInsurance = !_.isNil(payWithInsuranceState) ? payWithInsuranceState : payWithInsurance;

    const addressType = _.get(homeAddress, 'addressType', []);
    const selectedSaveAs = this.getSelectedSaveAs();
    const preSelectedCountry = _.get(user, ['me', 'preSelectedCountry'], false);

    let payment = {
      ...paymentMethod,
    };
    payment = {
      ...payment,
      ...homeAddress,
      addressCountry: _.get(homeAddress, 'addressCountry', preSelectedCountry),
    };

    const isMobile = _.get(this, ['state', 'isMobile'], false);
    const countryIsNonUS = _.get(user, ['me', 'countryIsNonUS'], false);
    const requireShippingAddress = _.get(user, ['me', 'requireShippingAddress'], false);
    const hasConsultation = isSchedulingLoaded && consultationTypes && consultationTypes.length > 0;
    if (isSchedulingLoaded && !hasConsultation) {
      return (
        <NoConsultationErrorScreen
          isMobile={isMobile}
          activeStage={activeStage}
          workflow={this.workflow}
          onBack={this.onBack}
          isSchedulingWizardPage={true}
          user={user}
          location={location}
          onLogout={this.onLogout}
          hasConsultation={hasConsultation}
        />
      );
    }

    return (
      <div
        className={classnames('scp-schedule-container', isMobile && 'mobile-schedule-container')}
      >
        {!isMobile && <h2 className="mobile-title">Schedule</h2>}
        <div className="scp-scheduling-page full-height no-footer">
          <BreadcrumbProgress
            activeStage={activeStage}
            workflow={this.workflow}
            onBack={this.onBack}
            isSchedulingWizardPage={true}
            user={user}
            location={location}
            onLogout={this.onLogout}
          />
          {activeStage === STAGE_SELECT_SERVICE && !this.onlyServiceName && (
            <SelectServicePageV2
              insuranceEnabled={insuranceEnabled}
              gotoCalendar={gotoCalendar}
              toPreviousStep={this.onBack}
              shippingAddress={shippingAddress}
              homeAddress={homeAddress}
              {...couponApplierProps}
              isMobile={isMobile}
              isPreSelectedFlow={this.isPreSelectedFlow()}
              history={history}
            />
          )}
          {activeStage === STAGE_APPOINTMENT_DETAILS && (
            <AppointmentDetails
              scheduleAction={this.reserveAppointment}
              onConfirm={() => this.onAppointmentReserved()}
              customPath="calendar"
              toPreviousStep={this.onBack}
              newPrice={newPrice}
              isPreSelectedFlow={this.isPreSelectedFlow()}
              gotoSelectService={gotoSelectService}
            />
          )}

          {activeStage === STAGE_INSURANCE_INFO && (
            <InsuranceInformationFormV2
              toPreviousStep={this.onBack}
              onSubmit={this.submitInsuranceInformation}
              companies={insuranceCompanies}
              preventInsuranceCompanies={preventInsuranceCompanies}
              initialValues={{
                ...insurance,
                ...homeAddress,
                payWithInsurance,
                isSelfPay: !payWithInsurance,
              }}
              scheduling={scheduling}
              paymentby={paymentby}
              isInsuranceDisable={isInsuranceDisable}
              is_payment_mode_insurance_only={is_payment_mode_insurance_only}
              patients_biological_sex={patients_biological_sex}
              loadDocuments={this.props.loadDocuments}
              loadDocumentTypes={this.props.loadDocumentTypes}
              resetnsuranceCardFront={this.props.resetnsuranceCardFront}
              resetInsuranceCardBack={this.props.resetInsuranceCardBack}
              planTypesEnums={planTypesEnums}
              handlePayWithInsurance={this.handlePayWithInsurance}
              isMobile={isMobile}
              CountriesStateEnums={CountriesStateEnums}
              allAddress={allAddress}
              {...this.props.insuranceInfoUploadProps}
            />
          )}
          {activeStage === STAGE_PAYMENT_INFO && (
            <PaymentInformationForm
              {...couponApplierProps}
              toPreviousStep={this.onBack}
              onSubmit={this.submitPaymentInformation}
              handlePaymentMethod={this.handlePaymentMethod}
              onSkip={this.gotoPaymentReview}
              formError={formError}
              initialValues={payment}
              companies={insuranceCompanies}
              insurance={insurance}
              paymentby={paymentby}
              allAddress={allAddress}
              homeAddress={homeAddress}
              shippingAddress={shippingAddress}
              user={user}
              CountriesStateEnums={CountriesStateEnums}
              loading={
                couponApplierProps.loading ||
                _.isEmpty(user.me) ||
                (user.me.hasPaymentMethod && _.isEmpty(user.paymentMethod))
              }
              getStripeIntent={getStripeIntent}
              isMobile={isMobile}
            />
          )}
          {activeStage === STAGE_REVIEW_PAYMENT && (
            <ReviewPaymentSummaryForm
              {...couponApplierProps}
              toPreviousStep={this.onBack}
              goNext={requireShippingAddress ? this.gotoShippingAddress : gotoConfirmAppointment}
              formError={formError}
              initialValues={payment}
              insurance={insurance}
              companies={insuranceCompanies}
              user={user}
              loading={
                couponApplierProps.loading ||
                _.isEmpty(user.me) ||
                (user.me.hasPaymentMethod && _.isEmpty(user.paymentMethod))
              }
              getStripeIntent={getStripeIntent}
              isMobile={isMobile}
            />
          )}

          {activeStage === STAGE_SHIPPING_ADDRESS && requireShippingAddress && (
            <ShippingAddressPage
              toPreviousStep={this.onBack}
              onSubmit={this.submitShippingAddressInformation}
              handlePaymentMethod={this.handlePaymentMethod}
              goNext={gotoConfirmAppointment}
              formError={formError}
              initialValues={{
                shippingAddressLine1: _.get(shippingAddress, 'addressLine1', ''),
                shippingAddressLine2: _.get(shippingAddress, 'addressLine2', ''),
                shippingAddressCity: _.get(shippingAddress, 'addressCity', ''),
                shippingAddressState: _.get(shippingAddress, 'addressState', ''),
                shippingAddressZipCode: _.get(shippingAddress, 'addressZipCode', ''),
                shippingAddressCountry: _.get(shippingAddress, 'addressCountry', 'US'),
                shippingSameAsHomeAddress: this.ShippingSameAsHome(),
              }}
              allAddress={allAddress}
              user={{
                ...user.me,
              }}
              CountriesStateEnums={CountriesStateEnums}
              isMobile={isMobile}
            />
          )}

          {activeStage === STAGE_APPOINTMENT_CONFIRM && (
            <ConfirmAppointmentForm
              toPreviousStep={this.onBack}
              onSubmit={this.submitConfirmationApp}
              formError={formError}
              appointment={reservedAppointment}
              selectedServiceName={servicePackageName}
              clientData={extClientData}
              isPackage={isPackage}
              paymentMethod={paymentMethod}
              insurance={insurance}
              companies={insuranceCompanies}
              newPrice={newPrice}
              me={user && user.me}
              bookAppointmentTimedOutError={bookAppointmentTimedOutError}
              show={modals.bookAppointmentErrorModal.open}
              onHide={() => {
                this.closeModal(modalNames.bookAppointmentErrorModal);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

SchedulingWizardPage.propTypes = {
  registrationWizard: PropTypes.object.isRequired,
  scheduling: PropTypes.object.isRequired,
  modals: PropTypes.object,
  appointment: PropTypes.object,
  appointmentLoading: PropTypes.bool,
  user: PropTypes.object.isRequired,
  needPaymentInfo: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  gotoCalendar: PropTypes.func.isRequired,
  gotoSelectService: PropTypes.func.isRequired,
  gotoConfirmAppointment: PropTypes.func.isRequired,
  gotoPaymentInfo: PropTypes.func.isRequired,
  gotoInsuranceInfo: PropTypes.func.isRequired,
  initializePaymentForm: PropTypes.func.isRequired,
  setSelectedService: PropTypes.func.isRequired,
  openCancelAppointment: PropTypes.func.isRequired,
  loadSchedulingIfNeeded: PropTypes.func.isRequired,
  loadPaymentMethod: PropTypes.func.isRequired,
  selectedService: PropTypes.string,
  selectedStage: PropTypes.string,
  needLoadPaymentMethod: PropTypes.bool,
  loading: PropTypes.bool,
  newPrice: PropTypes.number,
  paymentMethod: PropTypes.object,
  couponError: PropTypes.object,
  clearCoupon: PropTypes.func.isRequired,
  setCouponCode: PropTypes.func.isRequired,
  appliedCoupon: PropTypes.object,
  hiddenCouponApplied: PropTypes.bool,
  loadInsurance: PropTypes.func.isRequired,
  loadAllAddress: PropTypes.func.isRequired,
  loadInsuranceCompanies: PropTypes.func.isRequired,
  insurance: PropTypes.object,
  allAddress: PropTypes.array,
  insuranceCompanies: PropTypes.array,
  isInsuranceDisable: PropTypes.bool,
  preventInsuranceCompanies: PropTypes.array,
  shippingAddress: PropTypes.object,
  homeAddress: PropTypes.object,
};

const mapStateToProps = ({
  registrationWizard,
  scheduling,
  user,
  appointment,
  modals,
  documents,
  enums,
}) => ({
  registrationWizard,
  scheduling,
  user,
  modals,
  appointment: appointment.data,
  appointmentLoading: appointment.loading,
  insuranceInfoUploadProps: {
    frontInsuranceCardStatus: {
      uploading: documents.insuranceCardfrontuploaing,
      uploadingProgress: documents.insuranceCardfrontUploadingProgress,
      uploadingResult: documents.insuranceCardFrontUploadingResult,
    },
    backInsuranceCardStatus: {
      uploading: documents.insuranceCardbackuploaing,
      uploadingProgress: documents.insuranceCardbackUploadingProgress,
      uploadingResult: documents.insuranceCardbackUploadingResult,
    },
    me: user.me,
    documents: documents.data,
    loaded: documents.loaded,
  },
  documentTypes: documents.documentTypes,
  planTypesEnums: enums.planTypes.data,
  CountriesStateEnums: enums.countryState.data,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    registrationWizard,
    scheduling,
    user,
    modals,
    appointment,
    appointmentLoading,
    insuranceInfoUploadProps,
    documentTypes,
    planTypesEnums,
    CountriesStateEnums,
  } = stateProps;
  const { dispatch } = dispatchProps;
  const { history, match, location } = ownProps;
  const selectedService = match.params.service;
  const needPaymentInfo = scheduling.selectedProduct && scheduling.selectedProduct.price > 0;
  const priceIsZero = scheduling.selectedProduct && scheduling.selectedProduct.price == 0;
  const hasPaymentMethod = user.me && user.me.hasPaymentMethod;
  const { paymentMethod, insuranceCompanies } = user;
  let { insurance, me, allAddress, allAddressLoaded } = user;
  const homeAddress = getHomeAddress([...allAddress]);
  const shippingAddress = getShippingAddress([...allAddress]);
  const billingAddress = getBillingAddress([...allAddress]);
  let isInsuranceDisable = false;
  let company = insuranceCompanies.find((a) => a.name == CIGNA_INSURANCE);
  let kaisercompany = insuranceCompanies.find((a) => a.name == KAISER_INSURANCE);
  const preventInsuranceCompanies = _.filter(insuranceCompanies || [], (value) =>
    invalidInsuranceCompanyName.includes(value.name)
  );

  if (company && me && me.affiliation && me.affiliation == Cigna_GenericReferral) {
    isInsuranceDisable = true;
    if (insurance == null) insurance = { insuranceCompanyId: company.id };
  } else if (kaisercompany && me && me.affiliation && me.affiliation == Kaiser_NWReferral) {
    isInsuranceDisable = true;
    if (insurance == null) insurance = { insuranceCompanyId: kaisercompany.id };
  }

  return {
    dispatch,
    modals,
    registrationWizard,
    scheduling,
    user,
    appointment,
    appointmentLoading,
    selectedService,
    selectedStage: scpScheduling.find((s) => s.path === match.params.step).id,
    needPaymentInfo,
    priceIsZero,
    needLoadPaymentMethod: hasPaymentMethod && !paymentMethod && !user.paymentMethodLoading,
    paymentMethod,
    insurance,
    allAddress,
    allAddressLoaded,
    homeAddress,
    shippingAddress,
    billingAddress,
    insuranceCompanies,
    isInsuranceDisable,
    preventInsuranceCompanies,
    history,
    loadSchedulingIfNeeded: () => dispatch(loadSchedulingIfNeeded()),
    loadPaymentMethod: () => dispatch(loadPaymentMethod()),
    loadInsurance: () => dispatch(loadInsurance()),
    loadAllAddress: () => dispatch(loadAllAddress()),
    loadInsuranceCompanies: () => dispatch(loadInsuranceCompanies()),
    getPlanTypesEnums: () => dispatch(getPlanTypesEnums()),
    gotoCalendar: (service) => history.push(`/scheduling/${service || selectedService}/calendar`),
    gotoPaymentInfo: () => history.push(`/scheduling/${selectedService}/billing`),
    gotoInsuranceInfo: () => history.push(`/scheduling/${selectedService}/insurance`),
    gotoHomeAddress: () => history.push(`/scheduling/home-address`),
    gotoConfirmAppointment: () => history.push(`/scheduling/${selectedService}/confirm`),
    gotoPaymentReview: () => () => history.push(`/scheduling/${selectedService}/payment-review`),
    gotoSelectService: () => history.push('/scheduling/select-service'),
    initializePaymentForm: () => dispatch(initializeForm(PAYMENT_FORM_NAME)),
    setSelectedService: (service) => dispatch(setSelectedService(service)),
    openCancelAppointment: () =>
      dispatch(setModalMode(modalNames.cancelAppointment, { open: true })),
    loadDocuments: () => dispatch(loadDocuments()),
    loadDocumentTypes: () => dispatch(loadDocumentTypes()),
    insuranceInfoUploadProps,
    documentTypes,
    resetnsuranceCardFront: () => dispatch(resetnsuranceCardFront()),
    resetInsuranceCardBack: () => dispatch(resetInsuranceCardBack()),
    location,
    logout: () => dispatch(logout()),
    planTypesEnums,
    CountriesStateEnums,
    getStripeIntent: () => dispatch(SetupStripeIntent()),
    match,
  };
};

export default connect(
  mapStateToProps,
  null,
  mergeProps
)(withCouponApplier(withRouter(SchedulingWizardPage)));
