import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import './DaySelect.scss';

const DaySelect = ({ startDate, selectedDate, slots, handleNext, handlePrevious, updateSlots }) => {
  const showDate = () => {
    const endDate = moment(startDate).add(6, 'days');
    return `${moment(startDate).format('MMM. DD, YYYY')} - ${moment(endDate).format(
      'MMM. DD, YYYY'
    )}`;
  };

  const filterDays = () => {
    let dateArray = [];
    let currentDate = startDate;
    for (let i = 0; i < 7; i++) {
      const dates = Object.keys(slots);
      const formatedDate = moment(currentDate).format('YYYY-MM-DD');
      dateArray.push({
        date: moment(currentDate).format('DD'),
        month: moment(currentDate).format('MMM'),
        day: moment(currentDate).format('ddd'),
        disabled: dates.indexOf(formatedDate) == -1,
        active: moment(selectedDate).isSame(currentDate, 'day'),
        momentDate: moment(currentDate),
      });

      // Move to the next day
      currentDate = moment(currentDate).add(1, 'day');
    }
    return dateArray;
  };
  const getPreviousWeekStartDate = () => {
    return moment(startDate).add(-7, 'days');
  };

  const getNextWeekStartDate = () => {
    return moment(startDate).add(7, 'day');
  };

  const showPrevious = () => {
    return moment().isBefore(moment(startDate), 'day');
  };

  const _filterDays = filterDays();
  const previousStartDate = getPreviousWeekStartDate();
  const nextWeekStartDate = getNextWeekStartDate();

  return (
    <div className="day-select-container">
      <div className="choose-a-day">Choose a Day </div>
      <div className="date-time-week-select">
        <div>
          {showPrevious() && (
            <span onClick={() => handlePrevious(previousStartDate)}>
              <img className="arrow-left" src={require('./chevronLeft.svg')} />
            </span>
          )}
          <span className="date">{showDate()}</span>

          <span onClick={() => handleNext(nextWeekStartDate)}>
            <img className="arrow-right" src={require('./chevronRight.svg')} />
          </span>
        </div>
      </div>

      <div className="days-container">
        {_filterDays.map((day, i) => {
          return (
            <div
              key={`${day.date}-${day.month}-${i}`}
              className={classnames('days-list', {
                disabled: day.disabled,
                active: day.active,
              })}
              onClick={() => !day.disabled && updateSlots(day?.momentDate)}
            >
              <div className="day-name">{day.day}</div>
              <div className="date-name">
                <div>
                  <span>{day.date}</span>
                  {day.month}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DaySelect;
