import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import TestInfoBox from './TestInfoBox';

const TestInfoBoxContainer = ({type, encounter, className, geneticTestLab, geneticTestLoading}) =>{
  return (
    <TestInfoBox
      type={type}
      encounter={encounter}
      geneticTestLab={geneticTestLab}
      className={className}
      geneticTestLoading={geneticTestLoading}
      history={history}
    />
  );
}

TestInfoBoxContainer.propTypes = {
  className: PropTypes.string,
  encounters: PropTypes.array,
  type: PropTypes.string,
};

const mapDispatchToProps = () => ({});

const mapStateToProps = (state, ownProps) => {
  const { type, encounters, className } = ownProps;
  const sortedEncounters = _.orderBy(encounters, (el) => new moment(el.createdAt), ['desc']);
  const encounter = _.get(sortedEncounters, '0', {});
  const geneticTestLab = _.get(state, ['geneticTest', ['data']]);
  const geneticTestLoading = _.get(state, ['geneticTest', ['loading']]);
  return { type, encounter, className, geneticTestLab, geneticTestLoading };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestInfoBoxContainer);
