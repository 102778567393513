import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from 'components/modals/ModalDialog';
import './VideoModal.scss';

const VideoModal=({show, onCancel, videoURL, loop = 1})=> {

    return (
      <ModalDialog
        hideHeader={true}
        className="scp-video-modal-dialog md1061"
        show={show}
        onHide={onCancel}
        roleModal={'scp-video-modal'}>
        <div onClick={onCancel} className="close-video-btn">
          Close
        </div>
        <iframe
          src={`${videoURL}?portrait=0&autoplay=1&loop=${loop}&autopause=0`}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </ModalDialog>
    );
}

VideoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  videoURL: PropTypes.string,
  loop: PropTypes.number,
};

export default VideoModal;
