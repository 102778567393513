import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DocumentCard from './../DocumentCard';
import SingleSelect from './../../../../../../components/filters/SingleSelect/index';
import _ from 'lodash';
import moment from 'moment';

import './SummaryPage.scss';

const DOCUMENT_TYPE = 'DOCUMENT_TYPE';
const SERVICE_DATE = 'SERVICE_DATE';
const LAST_MODIFIED = 'LAST_MODIFIED';

const options = [
  {
    internal: 'DOCUMENT_TYPE',
    external: 'Document Type',
    pillLabel: 'Sort By Document Type',
  },
  {
    internal: 'SERVICE_DATE',
    external: 'Service Date',
    pillLabel: 'Sort By Service Date',
  },
  {
    internal: 'LAST_MODIFIED',
    external: 'Last Modified',
    pillLabel: 'Sort By Last Modified',
  },
];

const SummaryPage = ({ gmDocuments, downloadDocument }) => {
  const [sortBy, setSortBy] = useState(DOCUMENT_TYPE);

  const renderNoDocuments = () => {
    return <div className="no-visits-warn">You have no documents</div>;
  }

  const getTransformedData = () => {
    const documents = gmDocuments || [];
    const groupByDocs = groupByDocuments(documents);
    const keys = _.keys(groupByDocs);
    const sortKeys = sortdocumentKeys(keys);
    let result = {};
    _.map(sortKeys, key => {
      const values = groupByDocs[key];
      result[key] = orderByDocument(values);
    });
    return result;
  };

  const sortdocumentKeys = (keys) => {
    let result = [];

    if (sortBy == DOCUMENT_TYPE) {
      let tempKeys = keys;
      const genetickey = _.remove(tempKeys, key => {
        return key == 'Genetic test results';
      });

      const riskkey = _.remove(tempKeys, key => {
        return key == 'Risk assessment';
      });

      const adminMiscKey = _.remove(tempKeys, key => {
        return key == 'Administrative misc';
      });
      result = _.sortBy(tempKeys);
      result = _.concat(genetickey, riskkey, result);
      result = _.concat(result, adminMiscKey);
    } else if (sortBy == SERVICE_DATE) {
      let tempKeys = keys;
      const otherkey = _.remove(tempKeys, key => {
        return key == 'Other Documents';
      });
      result = _.orderBy(
        tempKeys,
        [
          key => {
            const split = _.split(key, '-')[0];
            return moment(split);
          },
        ],
        ['desc']
      );
      result = _.concat(result, otherkey);
    } else {
      result = keys;
    }
    return result;
  };

  const orderByDocument = (documents) => {
    if (sortBy == DOCUMENT_TYPE) {
      return _.orderBy(documents || [], [item => moment(item.modifiedAt)], ['desc']);
    } else if (sortBy == SERVICE_DATE) {
      return _.orderBy(
        documents || [],
        [
          item => item.relatedEncounter && moment(item.relatedEncounter.DOS),
          item => item.type && item.type.name,
        ],
        ['desc', 'asc']
      );
    } else if (sortBy == LAST_MODIFIED) {
      return _.orderBy(documents || [], [item => item.modifiedAt], ['desc']);
    }
    return documents;
  };

  const groupByDocuments = (documents,) => {
    let groupdocs = documents;
    if (sortBy == DOCUMENT_TYPE) {
      groupdocs = _.groupBy(documents, doc => {
        return doc.type.name;
      });
      return groupdocs;
    } else if (sortBy == SERVICE_DATE) {
      groupdocs = _.groupBy(documents, doc => {
        return doc.relatedEncounter
          ? `${moment(doc.relatedEncounter.DOS).format('MMM DD, YYYY')}${doc.relatedEncounter
            .consultation
            ? ' - ' + doc.relatedEncounter.consultation
            : ''}`
          : 'Other Documents';
      });
      return groupdocs;
    }
    return { '': [...groupdocs] };
  };

  const _getTransformedData = getTransformedData();

  return (
    <div className="scp-summary-page-block">
      <div className="scp-myheath-header">Genome Medical Documents</div>
      <div className="doc-filter-container">
        <SingleSelect
          data={options}
          currentValue={sortBy || DOCUMENT_TYPE}
          title={'Sort Documents'}
          setStateFunc={value => setSortBy(value)}
          defaultValue={DOCUMENT_TYPE}
          convertStrsToNums={false}
        />
      </div>
      {!_.isEmpty(_getTransformedData)
        ? _.map(_getTransformedData, (value, key) => {
          return (
            <div className="scp-visit-doc-block">
              <div className="scp-myheath-subheader">{key}</div>
              <div className="summary-cards-table">
                {Array.isArray(value) &&
                  value.length &&
                  value.map(doc => {
                    return (
                      <DocumentCard
                        document={doc}
                        variant={'gmDoc'}
                        key={doc.id}
                        downloadDocument={downloadDocument}
                      />
                    );
                  })}
              </div>
            </div>
          );
        })
        : renderNoDocuments()}
    </div>
  );


}

SummaryPage.propTypes = {
  appointments: PropTypes.object.isRequired,
  gmdocuments: PropTypes.array.isRequired,
  documentTypes: PropTypes.array,
};

export default SummaryPage;
