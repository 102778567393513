import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import noApptIcon from './no-appt.png';
import { withRouter } from 'react-router';
import authService from 'services/api/authService';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import { SetupVideoAppointment } from 'components/widgets/SCP/PatientCards';
import './PatientCards.scss';
import { CurbsideConsultation, CURBSIDE_15_CONSULATION_DURATION } from 'constants/ServiceTypes';
import {
  TODAY,
  FROM_ONE_TO_SEVEN_DAYS,
  AFTER_SEVEN_DAYS,
  NO_APPOINTMENT,
  IN_PROGRESS,
} from 'constants/HomeAppointmentCardGoals';

const SCHEDULE_TIME_DIFF = 15;

const UpcomingAppointment=({appointment,meOutreach,currentUser,history,goal})=>{

  const { isComplete, isMissed,id, isPast, mStartTime,mEndTime,consultation,startTime, endTime, timezoneAbb,provider, byPhone } =appointment;

  
  const differentFromCurrentToScheduleTime=()=> {
    return mStartTime.diff(moment().startOf('day'), 'days');
 }

  const _decidedGoal=(()=> {
    const inProgress = moment().isAfter(mStartTime) && !isMissed && !isComplete;

    if (_.isEmpty(appointment) || isComplete || (isPast && isMissed)) {
      if (!(!_.isNil(meOutreach) && !_.isEmpty(meOutreach))) {
        return NO_APPOINTMENT;
      } else return null;
    } else {
      const difference = differentFromCurrentToScheduleTime();
      if (difference >= 7) return AFTER_SEVEN_DAYS;
      else if (difference >= 1) return FROM_ONE_TO_SEVEN_DAYS;
      else if (!difference && inProgress) return IN_PROGRESS;
      else if (!difference) return TODAY;
      else return null;
    }
  })();

  const noUpcomingAppointment=()=> {
    return (
      <div className="upcoming-appointment">
        <div className="content">
          <div className="static">
            <div className="left">
              <div className="title">Schedule new appointment</div>
              <div className="text" data-hj-suppress>
                You have no upcoming appointments. Our Certified Genetic Counselors can guide you in
                making an informed decision about genetic testing and the implications of those
                results on your health. Schedule a new appointment today!
              </div>
            </div>
            <div className="right">
              <img className="visual-content" src={noApptIcon} alt="no appointment" />
            </div>
          </div>
          <div className="action-items left">
            <ButtonV2 path={'/scheduling/select-service'} className="no-appointment">
              Schedule New Appointment
            </ButtonV2>
          </div>
        </div>
      </div>
    );
  }

  const formattedDateAndTime=()=> {
    const _mStartTime = moment(startTime);
    const _mEndTime = moment(endTime);


    const formattedStart =
    _mStartTime.format('mm') === '00' ? _mStartTime.format('h') : _mStartTime.format('h:mm');

    const correctedEnd =
      consultation.type === CurbsideConsultation
        ? _mStartTime.add(CURBSIDE_15_CONSULATION_DURATION, 'minutes')
        : _mEndTime;
    const formattedEnd =
      moment(correctedEnd).format('mm') === '00'
        ? moment(correctedEnd).format('h A')
        : moment(correctedEnd).format('h:mm A');

    return `${formattedStart}-${formattedEnd} ${timezoneAbb}`;
  }
  
  const text=()=> {
    const { phone  } = currentUser

    if (_decidedGoal === TODAY && byPhone) {
      return (
        <Fragment>
          Your Certified Genetic Counselor, <b>{provider.full_name || provider.fullName}</b>, will
          call you at <b>{phone} </b>at the time of your appointment.
        </Fragment>
      );
    } else if (_decidedGoal === TODAY && !isItVideoTime) {
      return (
        <Fragment>
          You have an upcoming appointment with our certified genetic counselor,{' '}
          <b>{provider.full_name || provider.fullName}</b>. When you are within 15 minutes of the
          appointment, a button will appear to launch the video.
        </Fragment>
      );
    } else if (_decidedGoal === IN_PROGRESS) {
      return (
        <Fragment>
          {' '}
          You currently have an appointment in progress with our Certified Genetic Counselor,{' '}
          <b>{provider.full_name || provider.fullName}</b>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          You have an upcoming appointment with our certified genetic counselor,{' '}
          <b>{provider.full_name || provider.fullName}</b>.{' '}
        </Fragment>
      );
    }
  }

  const isItVideoTime= !byPhone && moment().add(SCHEDULE_TIME_DIFF, 'minutes').isAfter(mStartTime);

  const actionButton=()=> {
    if (isItVideoTime)
      return (
        <ButtonV2 href={`/appointments/${id}/waiting-room`} target="_blank">
          Launch the Video
        </ButtonV2>
      );
    else if ([FROM_ONE_TO_SEVEN_DAYS, AFTER_SEVEN_DAYS].includes(_decidedGoal))
      return (
        <div className="link">
          <Link to="/patient">
            You can click here to modify, reschedule, or cancel your appointment.
          </Link>
        </div>
      );
    else
      return (
        <div className="link">
          <Link to="/patient">View Details</Link>
        </div>
      );
  }

  const cardAction=()=> {
    if ([FROM_ONE_TO_SEVEN_DAYS, AFTER_SEVEN_DAYS].includes(_decidedGoal)) {
      history.push('/patient');
    } else {
      history.push('/patient');
    }
  }

  const type = _decidedGoal == IN_PROGRESS ? <span className="violet"> In Progress</span>: <span className="violet">Upcoming Appointment</span>;

  const title = (()=>{
    if (_decidedGoal === TODAY) {
      return <div className="today">Today</div>;
    } else if (_decidedGoal === IN_PROGRESS) {
      return <div className="today">In Progress</div>;
    }
    return null;
  })();
  const isCardClickable = false;
  if (!_decidedGoal || _decidedGoal !== goal) return null;
  else if (_decidedGoal === NO_APPOINTMENT) return noUpcomingAppointment();

  return (
    <Fragment>
      <div className="upcoming-appointment">
        {title}
        <div
          className="content"
          style={{ cursor: isCardClickable ? 'pointer' : 'auto' }}
          onClick={() => {
            if (isCardClickable) {
              cardAction();
            }
          }}
        >
          <div className="static">
            <div className="left">
              <div className="tag">{type}</div>
              <div className="title" data-hj-suppress>
                {consultation.name}
              </div>
              <div className="time" data-hj-suppress>
                {formattedDateAndTime()}
              </div>
              <div className="text" data-hj-suppress>
                {text()}
              </div>
            </div>
            <div className="right">
              <div className="date" data-hj-suppress>
                <div>{moment(startTime).format('MMM')}</div>
                <div>
                  <span>{moment(startTime).format('DD')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="action-items left">{actionButton()}</div>
        </div>
      </div>
      <SetupVideoAppointment appointment={appointment} />
    </Fragment>
  );
}

UpcomingAppointment.propTypes = {
  currentUser: PropTypes.object,
  appointment: PropTypes.object,
  appointmentLoaded: PropTypes.bool,
  isReviewQn: PropTypes.bool,
  showQuestionnaire: PropTypes.bool.isRequired,
  goal: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const {
    answers: { loaded, completed },
  } = state;

  return {
    currentUser: authService.getCurrentUser(state),
    appointment: state.appointment.data,
    appointmentLoaded: state.appointment.loaded && !state.appointment.loading,
    isAnswerLoaded: loaded,
    isAnswerReviewQn: completed,
    meOutreach: state.meOutreach.data && state.meOutreach.data,
  };
};

export default connect(mapStateToProps)(withRouter(UpcomingAppointment));
