import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { canShowSurveyQuestionnaire } from 'ducks/answers';
import authService from 'services/api/authService';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import './PatientCards.scss';
import { CurbsideConsultation, CURBSIDE_15_CONSULATION_DURATION } from 'constants/ServiceTypes';
import checklistIcon from './pre-appt-checklist.png';
import RBAC from 'components/high-order-components/RBAC/RBAC';

const Questionnaire = ({
  goal,
  isAnswerReviewQn,
  isAnswerLoaded,
  appointment,
  showQuestionnaire
}) => {
  if(appointment) return null;
  const { consultation, startTime, endTime, timezoneAbb, mStartTime } = appointment;

  const decidedGoal = (() => {
    if (goal == 'incomplete' && isAnswerLoaded && !isAnswerReviewQn) {
      return 'incomplete';
    } else if (goal == 'complete' && isAnswerLoaded && isAnswerReviewQn) {
      return 'complete';
    }
    return null;
  })();

  const formattedDateAndTime = (() => {
    const _mStartTime = moment(startTime);
    const _mEndTime = moment(endTime);
    const formattedStart =
      _mStartTime.format('mm') === '00' ? _mStartTime.format('h') : _mStartTime.format('h:mm');

    const correctedEnd =
      consultation.type === CurbsideConsultation
        ? _mStartTime.add(CURBSIDE_15_CONSULATION_DURATION, 'minutes')
        : _mEndTime;
    const formattedEnd =
      moment(correctedEnd).format('mm') === '00'
        ? moment(correctedEnd).format('h A')
        : moment(correctedEnd).format('h:mm A');

    return `${formattedStart}-${formattedEnd} ${timezoneAbb}`;
  })();

  const text = (() => {
    if (decidedGoal == 'incomplete') {
      return 'To help us make the most of our time together, please fill out the online questionnaire about your personal and family history. This should take a total of about 5 minutes.';
    } else if (decidedGoal == 'complete') {
      return 'You can review and update your completed questionnaire until the time of your appointment.  This will help us make the most of our time together.';
    }
    return null;
  })()

  const type = (() => {
    if (decidedGoal == 'incomplete') {
      return <span className="blue">Incomplete Task</span>;
    } else if (decidedGoal == 'complete') {
      return <span className="violet">Upcoming Appointment</span>;
    }
    return null;
  })();

  const title = (() => {
    if (decidedGoal == 'incomplete') {
      return 'Complete your online questionnaire';
    } else if (decidedGoal == 'complete') {
      return 'Review your online questionnaire';
    }
    return null;
  })();

  const actionButton = (() => {
    if (goal == 'complete' && isAnswerLoaded && isAnswerReviewQn) {
      return <ButtonV2 path={`/patient/questionnaire`}>Review Questionnaire</ButtonV2>;
    } else {
      return (
        <div className="link">
          <Link to="/patient/questionnaire">Launch the Questionnaire</Link>
        </div>
      );
    }
  })();

  if (_.isEmpty(appointment) || !showQuestionnaire || _.isNil(decidedGoal)) return null;
  return (
    <div className="upcoming-appointment">
      <div className="content">
        <div className="static">
          <div className="left">
            <div className="tag">{type}</div>
            <div className="title">{title}</div>
            <div className="time" data-hj-suppress>
              {`${consultation.name} ${formattedDateAndTime}`}
            </div>
            <div className="text" data-hj-suppress>
              {text}
            </div>
          </div>
          <div className="right">
            <img className="visual-content" src={checklistIcon} alt="upload your card" />
          </div>
        </div>
        <div className="action-items left">{actionButton}</div>
      </div>
    </div>
  );
}


Questionnaire.propTypes = {
  currentUser: PropTypes.object,
  appointment: PropTypes.object,
  appointmentLoaded: PropTypes.bool,
  isReviewQn: PropTypes.bool,
  showQuestionnaire: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    answers: { loaded, completed },
  } = state;

  return {
    currentUser: authService.getCurrentUser(state),
    appointment: state.appointment.data,
    appointmentLoaded: state.appointment.loaded && !state.appointment.loading,
    isAnswerLoaded: loaded,
    isAnswerReviewQn: completed,
    showQuestionnaire: canShowSurveyQuestionnaire(state),
  };
};

export default RBAC(['nonScp'], connect(mapStateToProps)(Questionnaire));
