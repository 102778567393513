import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { setModalMode } from 'ducks/modals';
import ModalNames from 'constants/ModalNames';
import {
  loadSchedulingIfNeeded,
  chooseAppointment,
  calendarDateChanged,
  loadAvailableAppointments,
  cancelSelectedAppointment,
  clearAvailableAppointments,
  getConsultation,
  loadAvailableDates,
} from 'ducks/scheduling';
import AppointmentCalendar from './AppointmentCalendar/AppointmentCalendar';
import './AppointmentDetails.scss';
import track, { TR_CALENDAR_PICK } from 'services/track';
import schedulingService from 'services/api/schedulingService';
import _ from 'lodash';
import Alert from 'components/widgets/Alert/Alert';
import gaTrack, { GA_TR_SCHEDULING_CALENDAR_PAGE } from '../../../../services/gaTrack';
import callIcon from './../../../../images/common/call_24.svg';
import emailIcon from './../../../../images/common/email_24.svg';

const AppointmentDetails = ({
  isPreSelectedFlow,
  gotoSelectService,
  isRescheduling,
  scheduling,
  calendarDateChanged,
  loadAvailableAppointments,
  dispatch,
  chooseAppointment,
  scheduleAction,
  onConfirm,
  toPreviousStep,
  me,
}) => {
  const {
    selectedAppointment: appointment,
    schedulingError,
    slots,
    reservations,
    isSchedulingLoaded,
    isLoadingAvailability,
    isLoadingAvailableDates,
    calendarStartTime,
    selectedServiceDescriptor,
    availableDates,
    calendarState,
    calendarTimezone,
    selectedProduct,
    consultationTypes,
    calendarModality,
    calendarCountry,
    calendarSpeciality,
  } = scheduling;

  const [confirmButtonEnabled, setConfirmButtonEnabled] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    gaTrack(GA_TR_SCHEDULING_CALENDAR_PAGE);
    window.scrollTo(0, 0);
    setIsMobile(schedulingService.isMobile());
    window.addEventListener('resize', onResize);
    if (isMobile) {
      calendarDateChanged(moment().add(1, 'days').startOf('day').toDate());
    }
    if (calendarState && calendarTimezone && calendarSpeciality) {
      loadAvailableAppointments();
    }

    /* reset insurance when you change appointment service */
    schedulingService.removeUseInsurance();
    if (!isRescheduling) {
      if (
        !isPreSelectedFlow &&
        !(calendarState && calendarTimezone && calendarSpeciality && calendarCountry)
      ) {
        gotoSelectService();
      }
      const { isOutreachAppointmentFlow } = schedulingService.getOutreachAppointmentData();

      if (isOutreachAppointmentFlow && !(calendarSpeciality && calendarCountry)) {
        gotoSelectService();
      }
    }
    return () => {
      window.removeEventListener('resize', onResize);
      dispatch(clearAvailableAppointments());
    };
  }, []);

  const onSlotClick = (sid, start, end) => {
    const consultation = getConsultation(
      selectedServiceDescriptor,
      selectedProduct,
      consultationTypes
    );

    chooseAppointment({
      start,
      end,
      provider: _.get(sid, 'providerId'),
      consultation: consultation,
      state: calendarState,
      timezone: calendarTimezone,
      vsee_specialty: _.get(sid, 'vsee_specialty', ''),
      modality: calendarModality,
      duration: _.get(sid, 'duration'),
      country: calendarCountry,
    });
    if (start) track(TR_CALENDAR_PICK, { date: start.toString() });
    handleConfirm();
  };

  const isCalendarShaded = () => {
    return !(calendarState && calendarTimezone && calendarSpeciality);
  };

  const onDateChanged = (date) => {
    calendarDateChanged(date);
    loadAvailableAppointments();
  };

  const handleConfirm = () => {
    setConfirmButtonEnabled(false);
    return scheduleAction().then(
      () => {
        onConfirm && onConfirm();
      },
      () => setConfirmButtonEnabled(true)
    );
  };

  const setViewMode = () => {
    if (isMobile && !schedulingService.isMobile()) {
      setIsMobile(false);
    } else if (!isMobile && schedulingService.isMobile()) {
      setIsMobile(true);
    }
  };

  const onResize = () => {
    setViewMode();
  };

  const renderHeader = () => {
    const { selectedProduct } = scheduling;
    if (!selectedProduct) return null;
    return (
      <h3 key="calendar-subheader" className="header-dark v2">
        Schedule Your Appointment
      </h3>
    );
  };

  return (
    <div className={classnames('appointment-details', { hidden: !isSchedulingLoaded })}>
      <div className="ap-calendar scp">
        {renderHeader()}
        <AppointmentCalendar
          slots={slots}
          availableDates={availableDates}
          reservations={reservations}
          date={calendarStartTime}
          onDateChanged={onDateChanged}
          onSlotClick={onSlotClick}
          isShaded={isCalendarShaded()}
          isLoading={isLoadingAvailability || isLoadingAvailableDates}
          isMobile={isMobile}
          isRescheduling={isRescheduling}
          toPreviousStep={toPreviousStep}
          isLoadingAvailableDates={isLoadingAvailableDates}
          isPreSelectedFlow={isPreSelectedFlow}
        />
        <Alert message={schedulingError} />

        <h6 className="header-need-help">Need more help?</h6>
        <div className="contact-mode-box-wrapper">
          <div className="contact-mode-box">
            <img src={callIcon} alt="Phone" />
            <span className="contact-mode">Call</span>
            <span>
              You can call our client support services at{' '}
              <span>
                <a className="cc-phone" href="tel:+18776880992">
                  1 (877) 688-0992
                </a>
              </span>
            </span>
          </div>

          <div className="contact-mode-box">
            <img src={emailIcon} alt="Email" />
            <span className="contact-mode">Email</span>
            <span>
              You can email our client support services at{' '}
              <a className="cc-email" href="mailto:clinical@genomemedical.com">
                clinical@genomemedical.com
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

AppointmentDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  scheduling: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  scheduleAction: PropTypes.func.isRequired,
  isRescheduling: PropTypes.bool,
  calendarDateChanged: PropTypes.func.isRequired,
  chooseAppointment: PropTypes.func.isRequired,
  loadSchedulingIfNeeded: PropTypes.func.isRequired,
  loadAvailableAppointments: PropTypes.func.isRequired,
  newPrice: PropTypes.number,
  onConfirm: PropTypes.func,
  consultation: PropTypes.object,
};

const mapStateToProps = ({
  scheduling,
  scheduling: { selectedServiceDescriptor, selectedProduct, consultationTypes },
  modals,
  user,
}) => ({
  scheduling,
  modals,
  consultation: getConsultation(selectedServiceDescriptor, selectedProduct, consultationTypes),
  me: user.me,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  loadAvailableAppointments: () => dispatch(loadAvailableAppointments()),
  loadAvailableDates: () => dispatch(loadAvailableDates()),
  calendarDateChanged: (date) => dispatch(calendarDateChanged(date)),
  chooseAppointment: (appointment) => dispatch(chooseAppointment(appointment)),
  loadSchedulingIfNeeded: (scheduling) => dispatch(loadSchedulingIfNeeded(scheduling)),
  setAppointmentModalMode: (mode) => dispatch(setModalMode(ModalNames.appointment, mode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails);
