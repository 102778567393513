import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import Toolbar from './Toolbar';
import AppointmentOptionsV2 from '../../scp/scheduling/AppointmentDetails/AppointmentOptions/AppointmentOptionsV2';

const SCPPatientsToolbar = ({ isArrowVisible, isRescheduling, clearCalendar }) => {
  return (
    <Fragment>
      <Toolbar
        left={[
          <AppointmentOptionsV2
            isArrowVisible={isArrowVisible}
            key={1}
            isRescheduling={isRescheduling}
            clearCalendar={clearCalendar}
          />,
        ]}
      />
    </Fragment>
  );
};

SCPPatientsToolbar.propTypes = {
  isArrowVisible: PropTypes.bool.isRequired,
  flow: PropTypes.string,
  clearCalendar: PropTypes.func.isRequired,
};

export default SCPPatientsToolbar;
