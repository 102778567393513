import _ from 'lodash';
import React, { useEffect,useRef,useState } from 'react';
import PropTypes from 'prop-types';
import UploadDocumentsModal from 'components/modals/SCP/UploadDocumentsModal/UploadDocumentsModal';
import DocumentCard from './../DocumentCard';
import Button from 'components/forms/controls/Button';
import ConfirmActionModal from 'components/modals/SCP/ConfirmActionModal/ConfirmActionModal';
import documentsService from 'services/api/documentsService';
import {
  deleteDocument,
  uploadDocuments,
  resetUploadingErrors,
  uploadDocumentInsuranceCard,
} from 'ducks/documents';
import { setModalMode } from 'ducks/modals';
import ModalNames from 'constants/ModalNames';
import StatusMessages from 'constants/StatusMessages';
import {
  CreateFileNameForInsuranceCard,
  createNewFileName,
  allowedDocumentUploadTypes,
} from 'services/utils';
import { documentTypesNames, INS_FRONT_CARD, INS_BACK_CARD } from 'constants/DocumentsConstants';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import ProgressBar from 'components/scp/forms/documents/Dropzone/ProgressBar';
import './ResultsPage.scss';
import { Loading, Tooltip } from 'gm-element-react';
import RedWarningIcon from './warning.svg';
import maliciousIcon from './../malicious.png';
import uploadIcon from 'images/common/upload-purple.svg';
import deleteIcon from 'images/common/delete-white.svg';

const BYTES_IN_MB = 1024 * 1024;
const insurancecardDetail = {
  accept: 'image/jpeg, image/png,image/gif',
  maxSize: 50 * BYTES_IN_MB,
  autoupload: true,
};

const ResultsPage = ({
  documents,dispatch,documentTypes, me,removingFiles,downloadDocument,
  loaded,
  uploadModal,
  uploadingStatus,
  frontInsuranceCardStatus,
  backInsuranceCardStatus,
}) =>{
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [newFileName, setNewFileName] = useState(null);
  const [docIdToDelete, setDocIdToDelete] = useState(undefined);
  const [frontRejectedFiles, setFrontRejectedFiles] = useState(false);
  const [backRejectedFiles, setBackRejectedFiles] = useState(false);
  const [uploadingDocument, setUploadingDocument] = useState(false);
  const [insuranceFrontImage, setInsuranceFrontImage] = useState(null);
  const [insuranceBackImage, setInsuranceBackImage] = useState(null);

  const dummyDivRef = useRef(null); 



  const fetchDocumentLinks = () => {
    if (InsuranceCardFront && InsuranceCardFront.isMalicious !== true) {
      documentsService.getDocumentLink(InsuranceCardFront.id).then(setInsuranceFrontImage);
    }
    if (InsuranceCardBack && InsuranceCardBack.isMalicious !== true) {
      documentsService.getDocumentLink(InsuranceCardBack.id).then(setInsuranceBackImage);
    }
  };


  useEffect(()=>{

    fetchDocumentLinks();
    window.addEventListener('scroll', handleScroll, true);
    return ()=>{
      window.removeEventListener('scroll', handleScroll, true);
    }
  },[]);

  useEffect(()=>{
    if(loaded){
      fetchDocumentLinks();
    }
  },[loaded]);


  const InsuranceCardFilter = (type = INS_FRONT_CARD) => {
    const filterstring =
      documentTypesNames.insuranceCard.toLowerCase() + '-' + type.toLowerCase() + '-';
    const filtercards = documents
      ? _.filter(documents, function(doc) {
          return (
            doc &&
            doc.type &&
            doc.type.name &&
            doc.type.name.toLowerCase() == documentTypesNames.insuranceCard.toLowerCase() &&
            doc.fileName.toLowerCase().indexOf(filterstring) !== -1
          );
        })
      : [];
    const sorteddocument = filtercards ? _.sortBy(filtercards, val => val.createdAt) : [];
    const insuranceCard = _.last(sorteddocument);
    return insuranceCard;
  };



  const InsuranceCardFront=(()=> {
    return InsuranceCardFilter(INS_FRONT_CARD);
  })();

  const InsuranceCardBack=(()=> {
    return InsuranceCardFilter(INS_BACK_CARD);
  })();

  const handleDeleteDocument = () => {
    dispatch(deleteDocument(docIdToDelete));
    setDocIdToDelete(undefined);
  };

  const openModal = () => {
    if (!_.isEmpty(selectedFiles)) resetSelectedFiles();
    dispatch(resetUploadingErrors());
    dispatch(setModalMode(ModalNames.uploadDocuments, { open: true }));
  };

  const closeModal = () => {
    dispatch(setModalMode(ModalNames.uploadDocuments, { open: false }));
  };

  const resetSelectedFiles = () => {
    setSelectedFiles([]);
    setNewFileName(null);
  };

  const handleSelectedFiles = filesData => {
    dispatch(resetUploadingErrors());
    setSelectedFiles([...filesData]);
  };

  const addSelectedFiles = filesData => {
    dispatch(resetUploadingErrors());
    setSelectedFiles([...filesData]);
  };

  const uploadInsurance=(filedata, type = INS_FRONT_CARD) =>{
    if (_.isArray(filedata) && filedata.length > 0) {
      const docType =
        documentTypes &&
        _.find(documentTypes, function(doc) {
          return doc && doc.name.toLowerCase() == documentTypesNames.insuranceCard.toLowerCase();
        });
      const filename = filedata[0].name || 'undefined.jpg';
      const ext = filename.substring(filename.lastIndexOf('.'), filename.length) || '';
      const newname = CreateFileNameForInsuranceCard(
        docType,
        _.get(me, 'firstName', ''),
        _.get(me, 'lastName', ''),
        ext,
        type
      );
      if (filedata && filedata.length > 0) {
        dispatch(uploadDocumentInsuranceCard(filedata[0], docType, newname, type));
      }
    }
  }

  const uploadSelectedDocuments = values => {
    const docTypes =
      documentTypes &&
      _.find(documentTypes, function(doc) {
        return doc && doc.id && doc.id == values.documentTypeId;
      });
    const ext =
      values.fileName.substring(values.fileName.lastIndexOf('.'), values.fileName.length) || '';
    const newfilenames = createNewFileName(
      docTypes,
      _.get(me, 'firstName', ''),
      _.get(me, 'lastName', ''),
      ext
    );
    setNewFileName(values.fileName);
    setUploadingDocument(true);
    dispatch(
          uploadDocuments(selectedFiles, {
            fileNames: newfilenames,
            description: values.description,
            documentTypeId: values.documentTypeId,
          })
        )
        .then(() => {
          setUploadingDocument(false);
          closeModal();
        })
        .catch(() => {
          setUploadingDocument(false);
          resetSelectedFiles();
        });
  };

  

  const isRemoving = doc => {
    return _(removingFiles).includes(doc.id);
  };

  const filterDocumentList = () => {
    if (_.isArray(documents)) {
      if (_.get(me, 'showInsuranceCardToPP', true)) {
        return documents.filter(doc => {
          return !isLatestInsuranceCard(doc);
        });
      }
      return documents;
    }
    return [];
  };

  const hasNoDocs = () => {
    const _documents = filterDocumentList();
    return _.isEmpty(_documents);
  };

  const getOnlyFileName = () => {
    const onlyFile = _(selectedFiles).first();
    return onlyFile && onlyFile.name;
  };

  const onPopoverClick = id => {
    setDocIdToDelete(id);
    dummyDivRef.current.click(); // required to hide the popover
  };

  const handleScroll = () => {
    dummyDivRef.current.click(); // required to hide the popover
  };

  const isLatestInsuranceCard = doc => {
    const latestinsurnacecardid = [];
    if (InsuranceCardBack && InsuranceCardBack.id)
      latestinsurnacecardid.push(InsuranceCardBack.id);
    if (InsuranceCardFront && InsuranceCardFront.id)
      latestinsurnacecardid.push(InsuranceCardFront.id);
    return (
      doc &&
      doc.type &&
      doc.type.name &&
      doc.type.name.toLowerCase() === documentTypesNames.insuranceCard.toLowerCase() &&
      doc.id &&
      _.find(latestinsurnacecardid, d => d == doc.id)
    );
  };

  const renderDocumentCards=()=> {
    const _documents = filterDocumentList();
    return (
      <div className="results-cards-table">
        {Array.isArray(_documents) &&
          _documents.map(doc => {
            const docId = doc.id;
            return (
              <DocumentCard
                document={doc}
                variant={'patient'}
                key={docId}
                downloadDocument={downloadDocument}
                onDelete={onPopoverClick}
              />
            );
          })}
      </div>
    );
  }

  const deleteInsuranceCard = (e, id) => {
    e.stopPropagation();
    onPopoverClick(id);
  };
  const backboxclasses = !InsuranceCardBack
  ? 'uploaddoc border-box back-border-box '
  : 'uploaddoc border-box uplpad-img-radius';
const frontboxclasses = !InsuranceCardFront
  ? 'uploaddoc border-box back-border-box '
  : 'uploaddoc border-box uplpad-img-radius';
const showInsuranceCardToPP = _.get(me, 'showInsuranceCardToPP', true);
return (
  <div className="scp-results-page-block ">
    <div className="scp-myheath-header">Documents Uploaded By You</div>
    {_.get(me, 'showInsuranceCardToPP', true) && (
      <div className="insurance-page-block">
        <h3 className="Your-insurance-card">Your Insurance Card</h3>
        <div className="insurance-upload-block" data-hj-suppress>
          <div className="upload-card">
            <span className="uploaddoctitle">Front</span>
            {_.isNull(_.get(InsuranceCardFront, ['isMalicious'], false)) ? (
              <Loading
                text="Scanning for viruses"
                loading={true}
                className={classnames(frontboxclasses, 'scanning')}>
                <div className="card-img">
                  {!_.isNil(insuranceFrontImage) && (
                    <img className="doc-img" src={insuranceFrontImage} />
                  )}
                </div>
              </Loading>
            ) : (
              <Dropzone
                className={classnames(
                  frontboxclasses,
                  {
                    'is-malicious': _.get(InsuranceCardFront, ['isMalicious'], false),
                  },
                  {
                    'is-uploaded-image': InsuranceCardFront,
                  }
                )}
                autoupload={insurancecardDetail.autoupload}
                multiple={false}
                maxSize={insurancecardDetail.maxSize}
                accept={insurancecardDetail.accept}
                onDrop={(acceptedFiles, rejectedFiles) => {
                  setFrontRejectedFiles(!_.isEmpty(rejectedFiles));
                  uploadInsurance(acceptedFiles, INS_FRONT_CARD);
                }}>
                {!InsuranceCardFront ? (
                  <div>
                    <div className="uploaddoc-dropblock">
                      <div className="text-center">
                        {' '}
                        <img className="cloud-img" src={require('images/upload.svg')} />
                      </div>
                      {frontInsuranceCardStatus &&
                        frontInsuranceCardStatus.uploading && (
                          <ProgressBar
                            className="dropzone-progressbar"
                            style={{ zIndex: 1000 }}
                            percent={
                              (frontInsuranceCardStatus &&
                                frontInsuranceCardStatus.uploadingProgress) ||
                              0
                            }
                            strokeColor={undefined}
                            trailColor={undefined}
                          />
                        )}
                      <div className="uploaddoctextContainerDesktop">
                        {' '}
                        <p className="uploaddoctext">
                          Drop file here or{' '}
                          <a href="javascript:;" className="uploaddoclink">
                            click to upload
                          </a>
                        </p>
                      </div>
                      <div className="uploaddoctextContainerMobile">
                        <div className="uploaddoctextContainerMobileText">
                          Upload&nbsp;
                          <span className="uploaddoctextContainerMobileTextBold">
                            front&nbsp;
                          </span>
                          image
                        </div>
                        <div className="uploaddoctextContainerMobileCTA">Browse Photos</div>
                      </div>
                    </div>
                  </div>
                ) : _.get(InsuranceCardFront, ['isMalicious'], false) ? (
                  <div className="card-img">
                    <Tooltip
                      className="insurance-card-virus-tooltip"
                      placement="top"
                      content="This file contains a virus and is blocked"
                      effect="dark">
                      <img
                        className="malicious-icon"
                        src={maliciousIcon}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      />
                    </Tooltip>
                    {frontInsuranceCardStatus &&
                      frontInsuranceCardStatus.uploading && (
                        <ProgressBar
                          className="dropzone-progressbar upload-progressbar"
                          style={{ zIndex: 1000 }}
                          percent={
                            (frontInsuranceCardStatus &&
                              frontInsuranceCardStatus.uploadingProgress) ||
                            0
                          }
                          strokeColor={undefined}
                          trailColor={undefined}
                        />
                      )}
                    <div className="update-image">
                      <div className="text-center hoverdoc-block">
                        {' '}
                        <img className="camera-img" src={require('images/camera.svg')} />
                      </div>
                      <p className="dochover-text">New Image</p>
                    </div>
                  </div>
                ) : (
                  <div className="card-img">
                    {!_.isNil(insuranceFrontImage) && (
                      <img className="doc-img" src={insuranceFrontImage} />
                    )}
                    {frontInsuranceCardStatus &&
                      frontInsuranceCardStatus.uploading && (
                        <ProgressBar
                          className="dropzone-progressbar upload-progressbar"
                          style={{ zIndex: 1000 }}
                          percent={
                            (frontInsuranceCardStatus &&
                              frontInsuranceCardStatus.uploadingProgress) ||
                            0
                          }
                          strokeColor={undefined}
                          trailColor={undefined}
                        />
                      )}
                    <div className="update-image">
                      <div className="text-center hoverdoc-block">
                        {' '}
                        <img className="camera-img" src={require('images/camera.svg')} />
                      </div>
                      <p className="dochover-text">New Image</p>
                    </div>
                    <div
                      className="delete-image"
                      onClick={e =>
                        deleteInsuranceCard(e, _.get(InsuranceCardFront, ['id']))}>
                      <img className="delete-icon" src={deleteIcon} />
                      <p className="dochover-text">Delete Image</p>
                    </div>
                  </div>
                )}
              </Dropzone>
            )}
            {((frontInsuranceCardStatus &&
              frontInsuranceCardStatus.uploadingResult &&
              frontInsuranceCardStatus.uploadingResult.status === false) ||
              frontRejectedFiles) && (
              <div className="error-div">
                <span className="error">
                  {(frontInsuranceCardStatus.uploadingResult &&
                    frontInsuranceCardStatus.uploadingResult.error) ||
                    (frontRejectedFiles && StatusMessages.rejectFiles)}
                </span>
              </div>
            )}
          </div>
          <div className="upload-card">
            <span className="uploaddoctitle">Back</span>
            {_.isNull(_.get(InsuranceCardBack, ['isMalicious'], false)) ? (
              <Loading
                text="Scanning for viruses"
                loading={true}
                className={classnames(backboxclasses, 'scanning')}>
                <div className="card-img">
                  {!_.isNil(insuranceBackImage) && (
                    <img className="doc-img" src={insuranceBackImage} />
                  )}
                </div>
              </Loading>
            ) : (
              <Dropzone
                className={classnames(
                  backboxclasses,
                  {
                    'is-malicious': _.get(InsuranceCardBack, ['isMalicious'], false),
                  },
                  {
                    'is-uploaded-image': InsuranceCardBack,
                  }
                )}
                autoupload={insurancecardDetail.autoupload}
                multiple={false}
                maxSize={insurancecardDetail.maxSize}
                accept={insurancecardDetail.accept}
                onDrop={(acceptedFiles, rejectedFiles) => {
                  setBackRejectedFiles(!_.isEmpty(rejectedFiles));
                  uploadInsurance(acceptedFiles, INS_BACK_CARD);
                }}>
                {!InsuranceCardBack ? (
                  <div>
                    <div className="uploaddoc-dropblock">
                      <div className="text-center">
                        {' '}
                        <img className="cloud-img" src={require('images/upload.svg')} />
                      </div>
                      {backInsuranceCardStatus &&
                        backInsuranceCardStatus.uploading && (
                          <ProgressBar
                            className="dropzone-progressbar"
                            style={{ zIndex: 1000 }}
                            percent={
                              (backInsuranceCardStatus &&
                                backInsuranceCardStatus.uploadingProgress) ||
                              0
                            }
                            strokeColor={undefined}
                            trailColor={undefined}
                          />
                        )}
                      <div className="uploaddoctextContainerDesktop">
                        {' '}
                        <p className="uploaddoctext">
                          Drop file here or{' '}
                          <a href="javascript:;" className="uploaddoclink">
                            click to upload
                          </a>
                        </p>
                      </div>
                      <div className="uploaddoctextContainerMobile">
                        <div className="uploaddoctextContainerMobileText">
                          Upload&nbsp;
                          <span className="uploaddoctextContainerMobileTextBold">
                            back&nbsp;
                          </span>
                          image
                        </div>
                        <div className="uploaddoctextContainerMobileCTA">Browse Photos</div>
                      </div>
                    </div>
                  </div>
                ) : _.get(InsuranceCardBack, ['isMalicious'], false) ? (
                  <div className="card-img">
                    <Tooltip
                      className="insurance-card-virus-tooltip"
                      placement="top"
                      content="This file contains a virus and is blocked"
                      effect="dark">
                      <img
                        className="malicious-icon"
                        src={maliciousIcon}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      />
                    </Tooltip>
                    {backInsuranceCardStatus &&
                      backInsuranceCardStatus.uploading && (
                        <ProgressBar
                          className="dropzone-progressbar upload-progressbar"
                          style={{ zIndex: 1000 }}
                          percent={
                            (backInsuranceCardStatus &&
                              backInsuranceCardStatus.uploadingProgress) ||
                            0
                          }
                          strokeColor={undefined}
                          trailColor={undefined}
                        />
                      )}
                    <div className="update-image">
                      <div className="text-center hoverdoc-block">
                        {' '}
                        <img className="camera-img" src={require('images/camera.svg')} />
                      </div>
                      <p className="dochover-text">New Image</p>
                    </div>
                  </div>
                ) : (
                  <div className="card-img">
                    {!_.isNil(insuranceBackImage) && (
                      <img className="doc-img" src={insuranceBackImage} />
                    )}
                    {backInsuranceCardStatus &&
                      backInsuranceCardStatus.uploading && (
                        <ProgressBar
                          className="dropzone-progressbar upload-progressbar"
                          style={{ zIndex: 1000 }}
                          percent={
                            (backInsuranceCardStatus &&
                              backInsuranceCardStatus.uploadingProgress) ||
                            0
                          }
                          strokeColor={undefined}
                          trailColor={undefined}
                        />
                      )}
                    <div className="update-image">
                      <div className="text-center hoverdoc-block">
                        {' '}
                        <img className="camera-img" src={require('images/camera.svg')} />
                      </div>
                      <p className="dochover-text">New Image</p>
                    </div>
                    <div
                      className="delete-image"
                      onClick={e =>
                        deleteInsuranceCard(e, _.get(InsuranceCardBack, ['id']))}>
                      <img className="delete-icon" src={deleteIcon} />
                      <p className="dochover-text">Delete Image</p>
                    </div>
                  </div>
                )}
              </Dropzone>
            )}
            {((backInsuranceCardStatus &&
              backInsuranceCardStatus.uploadingResult &&
              backInsuranceCardStatus.uploadingResult.status === false) ||
              backRejectedFiles) && (
              <div className="error-div">
                <span className="error">
                  {(backInsuranceCardStatus.uploadingResult &&
                    backInsuranceCardStatus.uploadingResult.error) ||
                    (backRejectedFiles && StatusMessages.rejectFiles)}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    )}

    <div className="results-actions-bar other-doc-block">
      <div className={`other-doc-header ${showInsuranceCardToPP ? '' : 'hide-insurance-card'}`}>
        <span className="Title">Other Documents</span>
        <Button onClick={openModal} className="results-upload-btn" type="submit">
          <span className="btn-inner-container">
            <span className="btn-icon">
              <img src={uploadIcon} />
            </span>
            <span>Upload File</span>
          </span>
        </Button>
      </div>
      {documents?.length > 0 && me ? renderDocumentCards() : null}
      {loaded &&
        hasNoDocs() && <div className="no-docs-warn">You have no attached documents</div>}
      <ConfirmActionModal
        roleModal={'scp-modal'}
        show={!_.isNil(docIdToDelete)}
        confirmText="Delete File"
        header="Are you sure you want to delete this file?"
        onConfirm={handleDeleteDocument}
        onCancel={() => setDocIdToDelete(undefined)}
        modalHeader={
          <div className="header-text">
            <img src={RedWarningIcon} /> <span>{'Delete File'}</span>
          </div>
        }
      />
      <UploadDocumentsModal
        roleModal={'scp-modal'}
        show={uploadModal && uploadModal.open}
        onCancel={closeModal}
        dropzoneOnDrop={addSelectedFiles}
        setAcceptedFiles={handleSelectedFiles}
        onRemove={handleDeleteDocument}
        uploadingStatus={uploadingStatus}
        onSubmit={uploadSelectedDocuments}
        documentTypes={
          documentTypes && documentTypes.filter(d => allowedDocumentUploadTypes(d))
        }
        initialValues={{ fileName: getOnlyFileName() }}
        newFileNames={[newFileName]}
        allFieldsEnable={!_.isEmpty(selectedFiles)}
        modalHeader={<div className="header-text">Upload Document</div>}
        uploadingDocument={uploadingDocument}
      />
    </div>
    <div key={'dummydiv'} ref={dummyDivRef} />
  </div>
);

}

ResultsPage.propTypes = {
  uploadModal: PropTypes.object.isRequired,
  uploadingStatus: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  loaded: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  removingFiles: PropTypes.array.isRequired,
  documentTypes: PropTypes.array,
  frontInsuranceCardStatus: PropTypes.object.isRequired,
  backInsuranceCardStatus: PropTypes.object.isRequired,
};

export default ResultsPage;
