import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ButtonV2 from 'components/forms/controls/ButtonV2';
import './PatientCards.scss';
import incompleteIcon from './incomplete-task.svg';

const IncompleteTask = ({goal}) =>{

  const goalIsAppointment = goal == 'appointment'
  const title = goalIsAppointment ?'Let’s finish scheduling your appointment':'';
  const description = goalIsAppointment? 'It seems you did not complete scheduling your appointment. No worries. We have saved your progress! If you have any questions or need any help, do not hesitate to contact our team.':'';
  const actionButton = goalIsAppointment ? <ButtonV2 path={"/scheduling/select-service"}>Continue Scheduling</ButtonV2> :'';

  return (
    <Fragment>
      {title &&
        description &&
        actionButton && (
          <div className="upcoming-appointment">
            <div className="content">
              <div className="static">
                <div className="left">
                  <div className="tag">
                    <span className="blue">Incomplete Task</span>
                  </div>
                  <div className="title">{title}</div>
                  <div className="text">{description}</div>
                </div>
                <div className="right">
                  <img className="visual-content" src={incompleteIcon} alt="Incomeplete Task" />
                </div>
              </div>
              <div className="action-items left">{actionButton}</div>
            </div>
          </div>
        )}
    </Fragment>
  );

}

IncompleteTask.propTypes = {
  goal: PropTypes.string.isRequired,
};

export default IncompleteTask;