import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'components/forms/controls/Select';

import { GetStateWiseTimezone } from 'services/geo';
import { getCountriesOption, getCountryStates, getModality } from 'services/utils';
import {
  selectStateByUser,
  selectTimezoneByUser,
  selectSpecialityByUser,
  loadAvailableAppointments,
  selectDefaultSpecialityByUser,
  selectProviderByUser,
  selectModalityByUser,
  selectCountryByUser,
} from 'ducks/scheduling';

import './AppointmentOptionsV2.scss';
import classnames from 'classnames';
import _ from 'lodash';
import { FollowUpConsultation } from '../../../../../constants/ServiceTypes';
import schedulingService from '../../../../../services/api/schedulingService';

const modalities = getModality();

const AppointmentOptions = ({
  scheduling,
  appointment,
  user,
  dispatch,
  clearCalendar,
  isRescheduling,
  CountriesStateEnums: { states, countries },
}) => {
  const { me } = user;
  const { partnerSpecialties, isPhoneOnly } = me;

  const {
    calendarCountry,
    calendarState,
    calendarTimezone,
    calendarSpeciality,
    selectedProduct,
    calendarProvider,
    exceptionalSpecialty,
    calendarModality,
  } = scheduling;

  useEffect(() => {
    const { calendarCountry, calendarSpeciality, exceptionalSpecialty } = scheduling;
    const { isOutreachAppointmentFlow } = schedulingService.getOutreachAppointmentData();
    const { me } = user;
    const { isPhoneOnly } = me;

    if (isPhoneOnly) {
      onModalityChange(isPhoneOnly);
    }
    const partnerSpecialties = _.get(user, ['me', 'partnerSpecialties'], []);

    let currentSpeciality = calendarSpeciality;
    if (!currentSpeciality && _.get(appointment, 'latestStatus.status', null) === 'booked') {
      currentSpeciality = _.get(appointment, 'vsee_specialty')
        ? _.get(appointment, 'vsee_specialty', '').replace('_Willing', '')
        : '';
      dispatch(selectSpecialityByUser(currentSpeciality));
    }
    if (!calendarSpeciality && !currentSpeciality) {
      let defaultCalendarSpeciality = _.get(user, 'me.vsee_specialty', currentSpeciality);
      if (partnerSpecialties && partnerSpecialties.length == 1) {
        const selectedSpecialtyObject = partnerSpecialties[0];
        defaultCalendarSpeciality = selectedSpecialtyObject['name'];
      }
      dispatch(selectDefaultSpecialityByUser(defaultCalendarSpeciality));
    }

    const selectedSpeciality = !calendarSpeciality
      ? _.get(user, 'me.vsee_specialty', currentSpeciality)
      : calendarSpeciality;

    if (
      !isOutreachAppointmentFlow &&
      !(partnerSpecialties || []).find((e) => e && e.name && e.name == selectedSpeciality)
    ) {
      if (partnerSpecialties && partnerSpecialties.length === 1) {
        const selectedSpecialtyObject = partnerSpecialties[0];
        const selectedSpecialty = selectedSpecialtyObject['name'];
        dispatch(selectSpecialityByUser(selectedSpecialty));
      } else {
        dispatch(selectSpecialityByUser(null));
      }
    }

    if (exceptionalSpecialty) dispatch(selectSpecialityByUser(exceptionalSpecialty));

    const allowedCountries = _.get(user, ['me', 'allowedCountries'], ['US']);
    if (!calendarCountry && allowedCountries && allowedCountries.length > 0) {
      dispatch(selectCountryByUser(allowedCountries[0]));
    }
  }, []);

  const onStateChanged = (e) => {
    clearCalendar();
    dispatch(selectStateByUser(e));
    dispatch(loadAvailableAppointments());
  };
  const onCountryChanged = (e) => {
    clearCalendar();
    dispatch(selectCountryByUser(e));
    dispatch(loadAvailableAppointments());
  };
  const onTimezoneChanged = (e) => {
    clearCalendar();
    dispatch(selectTimezoneByUser(e));
    dispatch(loadAvailableAppointments());
  };

  const onSpecialitiesChange = (e) => {
    clearCalendar();
    dispatch(selectSpecialityByUser(e));
    dispatch(loadAvailableAppointments());
  };

  const onModalityChange = (e) => {
    dispatch(selectModalityByUser(e));
  };

  const onProviderChange = (e) => {
    clearCalendar();
    dispatch(selectProviderByUser(e));
    dispatch(loadAvailableAppointments());
  };

  const isTimeZoneSelectShaded = () => {
    return !scheduling.calendarState;
  };

  const getProviders = () => {
    const { OutreachAppointment } = schedulingService.getOutreachAppointmentData();
    let options = [];

    if (!_.isNil(OutreachAppointment)) {
      options.push({
        key: OutreachAppointment?.provider.fullName,
        value: OutreachAppointment?.provider.id,
      });
    }
    options.push({
      key: 'All Available Providers',
      value: null,
    });

    return options;
  };

  const showSpeciality = () => {
    const consultationType = _.get(appointment, ['consultation', 'type']);
    return isRescheduling && consultationType !== FollowUpConsultation;
  };

  const { OutreachAppointment } = schedulingService.getOutreachAppointmentData();
  const allowedCountries = _.get(user, ['me', 'allowedCountries'], ['US']);
  const timezones = GetStateWiseTimezone(calendarState, calendarCountry);
  const disabledTimezone = timezones?.length <= 1;
  let selectedSpecialty = calendarSpeciality;
  if (partnerSpecialties.length === 1) {
    selectedSpecialty = partnerSpecialties[0].name;
  }
  const isCalendarTimeZoneDisabled = isTimeZoneSelectShaded() || disabledTimezone;
  const StateLabel =
    calendarCountry == 'CA' ? 'Province at Time of Visit' : 'State at Time of Visit';
  const countryIsNonUS = _.get(user, ['me', 'countryIsNonUS'], false);
  if (!selectedProduct) return null;

  return (
    <Fragment>
      <div className="appointment-options scp v2">
        {!_.isNil(OutreachAppointment) ? (
          <div className="select-field select-provider">
            <p className="select-title">Genetic Counselor</p>
            <Select
              className={classnames('gm-select')}
              value={calendarProvider}
              onChange={onProviderChange}
              placeholder="Select"
              options={getProviders().map((el) => {
                return { ...el, label: el.key, value: el.value };
              })}
            >
              {getProviders().map((el) => {
                return <Select.Option key={el.key} label={el.key} value={el.value} />;
              })}
            </Select>
          </div>
        ) : null}

        {countryIsNonUS && (
          <div className="select-field select-country">
            <p className="select-title">Country at Time of Visit</p>
            <Select
              className={classnames('gm-select')}
              value={calendarCountry}
              options={getCountriesOption(countries, allowedCountries)}
              onChange={onCountryChanged}
              placeholder={'Select...'}
            />
          </div>
        )}

        <div className="select-field select-state v2">
          <p className="select-title">{StateLabel}</p>
          <Select
            className={classnames('gm-select')}
            value={calendarState}
            options={getCountryStates(states, calendarCountry) || []}
            onChange={onStateChanged}
            placeholder={'Select...'}
            typeahead={true}
            clearable={true}
          />
        </div>

        <div className="select-field select-timezone">
          <p className="select-title">Time Zone at Time of Visit</p>
          <Select
            className={classnames('gm-select')}
            value={calendarTimezone}
            onChange={onTimezoneChanged}
            options={timezones || []}
            disabled={isCalendarTimeZoneDisabled}
            placeholder={'Select...'}
          />
        </div>

        {showSpeciality() && (
          <div className="select-field select-speciality">
            <p className="select-title">Specialty</p>
            <Select
              className={classnames('gm-select')}
              value={exceptionalSpecialty || selectedSpecialty}
              onChange={onSpecialitiesChange}
              options={partnerSpecialties.map((el) => {
                return { ...el, label: el.display_name, value: el.name };
              })}
              placeholder={'Select...'}
            />
          </div>
        )}

        {!isPhoneOnly && (
          <div className="select-field select-appointment-type">
            <p className="select-title">Appointment Type</p>
            <Select
              className={classnames('gm-select')}
              value={calendarModality}
              onChange={onModalityChange}
              options={modalities.map((el) => {
                return { ...el, label: el.display_name, value: el.name };
              })}
            />
          </div>
        )}
      </div>
      {calendarCountry == 'CA' && (
        <div className="canada-language">
          <div className="canada-title"> Canadian Restriction</div>
          <div className="description">
            <p>
              Genome Medical provides genetic counseling services to patients in the U.S. and
              Canada. Due to licensing restrictions, our board-certified, U.S.-based physicians are
              unable to order genetic tests for patients who reside outside of the United States.
            </p>
            <br />
            <p>
              Please continue this registration process to meet with one of our licensed genetic
              counselors who will be happy to help you understand the genetic testing process and
              possible tests that could be coordinated through your Canadian primary care provider,
              or to review any genetic test results you have already received.
            </p>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  scheduling: state.scheduling,
  user: state.user,
  appointment: _.get(state, 'appointment.data', {}),
  CountriesStateEnums: _.get(state, ['enums', 'countryState', 'data'], {}),
});

AppointmentOptions.propTypes = {
  scheduling: PropTypes.object.isRequired,
  isArrowVisible: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  clearCalendar: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AppointmentOptions);
