//React & Redux
import React from 'react';

//UI Libraries
import { Radio } from 'gm-element-react';

//Lodash
import get from 'lodash/get';

//Styles
import './SelectSpecialityRadioGroup.scss';

const SelectSpecialityRadioGroup = ({
  data,
  currentValue,
  onChangeFunc,
  customStyling,
  rowVariant,
}) => {
  const rowVariantClass = rowVariant ? ' radioButtonRowVariantContainer' : '';
  const containerClass = 'radioButtonGroupContainer' + rowVariantClass;
  const rowVariantRadioClass = rowVariant ? ' rowVariantRadioButton' : '';
  const radioButtonNonBlankClass =
    'singleSelectRadioRow singleSelectModalRow radioButtonRowContainer' + rowVariantRadioClass;
  const rowVariantLabelClass = rowVariant ? ' rowVariantRadioLabel' : '';
  const radioButtonLabelClass = 'singleSelectRadioLabel' + rowVariantLabelClass;

  return (
    <div className={containerClass} style={{ ...customStyling }}>
      {Array.isArray(data) &&
        data.map((el) => {
          const checked = currentValue === el.name;
          return (
            <div onClick={() => onChangeFunc(el.name)}>
              <Radio
                value={el.name}
                checked={checked}
                key={el.name}
                className={radioButtonNonBlankClass}
              >
                <div className={radioButtonLabelClass}>
                  <span style={{ color: checked ? '#5B43BD' : 'black' }}>
                    {el.display_name || '--'}
                  </span>
                  <div>{el.description}</div>
                </div>
              </Radio>
            </div>
          );
        })}
    </div>
  );
};

export default SelectSpecialityRadioGroup;
