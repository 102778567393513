import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import './PatientCards.scss';
import noPrvdrIcon from './no-appt.png';
import {
  FOLLOWUP_HIGH_PRIORITY_CARD,
  FOLLOWUP_LOW_PRIORITY_CARD,
} from 'constants/HomeAppointmentCardGoals';

const FollowupAppointment = ({
  goal,
  isGrailFlowAffiliation,
  meOutreach, history

}) => {
  const { provider, mStartTime } = { ...meOutreach.appointment };
  const encounterType = _.get(meOutreach, ['encounterType'], '');
  const appointmentID = _.get(meOutreach, ['appointmentID'], '');
  const isManualvisit = encounterType == 'visit' && appointmentID == null;

  const [followupAptmntExpand, setFolloupAptmntExpand] = useState(false);
  const [imageLoadingFailed, setImageLoadingFailed] = useState(false);

  useEffect(() => {
    if (goal == FOLLOWUP_HIGH_PRIORITY_CARD || isGrailFlowAffiliation) {
      setFolloupAptmntExpand(true);
    }
  }, []);

  const handleImageLoadingError = () => {
    setImageLoadingFailed(true);
  };

  const goToScheduling = () => {
    if (encounterType != 'visit' || appointmentID == null) history.push(`/patient/outreach`);
    else history.push(`/patient/outreach/${meOutreach.appointmentID}`);
  }

  const followupAppointmentHeader = () => {
    const testName = !_.isNil(_.get(meOutreach, ['testDisplayName'], ''))
      ? _.get(meOutreach, ['testDisplayName'], '')
      : '';
    let headerText = '';

    switch (encounterType) {
      case 'visit':
        if (appointmentID != null) {
          headerText = `Your past appointment with ${_.get(provider, 'fullName', '')} on ${' '} ${mStartTime && mStartTime.format('MMM Do, YYYY')
            }`;
        } else {
          headerText = `Your past appointment on ${' '} ${!_.isNil(meOutreach.DOS) && moment(meOutreach.DOS).format('MMM Do, YYYY')
            }`;
        }
        break;
      case 'cc-intake':
      case 'group-session':
        headerText = `Your past appointment on ${' '} ${!_.isNil(meOutreach.DOS) && moment(meOutreach.DOS).format('MMM Do, YYYY')
          }`;
        break;
      case 'scp':
        headerText = `Your ${testName} Test`;
        break;
      default:
        headerText;
    }
    return headerText;
  };

  const followupAppointmentBody = () => {
    const labName = !_.isNil(_.get(meOutreach, ['labDisplayName'], ''))
      ? _.get(meOutreach, ['labDisplayName'], '')
      : '';
    const testName = !_.isNil(_.get(meOutreach, ['testDisplayName'], ''))
      ? _.get(meOutreach, ['testDisplayName'], '')
      : '';
    let bodyText = '';

    switch (encounterType) {
      case 'visit':
        if (appointmentID != null) {
          bodyText = (
            <span>
              Your test results have come back for your previous appointment on{' '}
              <span className="bold">{mStartTime && mStartTime.format('MMM Do, YYYY')}.</span> If
              you'd like to discuss the results with your counselor{' '}
              <span className="bold">{_.get(provider, 'fullName', '')},</span> you can schedule an
              appointment.
            </span>
          );
        } else {
          bodyText = (
            <span>
              Your test results have come back for your previous appointment on{' '}
              <span className="bold">
                {!_.isNil(meOutreach.DOS) && moment(meOutreach.DOS).format('MMM Do, YYYY')}.
              </span>{' '}
              If you'd like to discuss the results with one of our Certified Genetic Counselors, you
              can schedule an appointment.
            </span>
          );
        }

        break;
      case 'cc-intake':
      case 'group-session':
        bodyText = (
          <span>
            Your test results have come back for your previous appointment on{' '}
            <span className="bold">
              {!_.isNil(meOutreach.DOS) && moment(meOutreach.DOS).format('MMM Do, YYYY')}.
            </span>{' '}
            If you'd like to discuss the results with one of our Certified Genetic Counselors, you
            can schedule an appointment.
          </span>
        );
        break;
      case 'scp':
        bodyText = (
          <span>
            Results for your <span className="bold">{testName}</span> by{' '}
            <span className="bold">{labName}</span> have come back. If you'd like to discuss the
            results with one of our Certified Genetic Counselors, you can schedule an appointment.
          </span>
        );
        break;
      default:
        bodyText;
    }
    return bodyText;
  };

  return (
    <Fragment>
      <div className="followup-appointment-wrapper">
        <div
          className="header followup-appointment-header"
          onClick={() => setFolloupAptmntExpand(!followupAptmntExpand)
          }
        >
          <span>{followupAppointmentHeader()}</span>
          <img
            src={require('images/common/arrow-gray.svg')}
            alt=""
            className="accordian-icon"
            style={{
              transform:
                followupAptmntExpand === true
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
            }}
          />
        </div>
        <div
          className="content"
          style={{ display: followupAptmntExpand === true ? 'block' : 'none' }}
        >
          <div className="static">
            <div className="left">
              <span className="test-status">Results Ready</span>
              <div className="text" data-hj-suppress>
                {followupAppointmentBody()}
              </div>
            </div>
            {encounterType === 'visit' && !isManualvisit && (
              <div className="right">
                {imageLoadingFailed ? (
                  <img className="provider-icon" src={noPrvdrIcon} alt="no provider" />
                ) : (
                  <img
                    className="provider-icon"
                    src={_.get(provider, ['photoSmall'])}
                    onError={handleImageLoadingError}
                  />
                )}
              </div>
            )}
          </div>
          <div className="action-items left">
            <ButtonV2
              onClick={() => goToScheduling()}
              className={classnames({
                'btn-fu-aptmnt-low-priority-card': goal == FOLLOWUP_LOW_PRIORITY_CARD,
                'btn-fu-aptmnt-high-priority-card': goal == FOLLOWUP_HIGH_PRIORITY_CARD,
              })}
            >
              {encounterType === 'visit' && !isManualvisit
                ? 'Schedule Follow Up Appointment'
                : 'Schedule Appointment'}
            </ButtonV2>
          </div>
        </div>
      </div>
    </Fragment>
  );

}
FollowupAppointment.propTypes = {
  goal: PropTypes.string,
  meOutreach: PropTypes.object,
  dispatch: PropTypes.func,
};

export default withRouter(FollowupAppointment);
