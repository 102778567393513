import React, { Component } from 'react';
import './SetupVideoAppointment.scss';
import videoIcon from './video.svg';
import _ from 'lodash';

const SetupVideoAppointment = ({ appointment }) => {

  if (
    _.isEmpty(appointment) ||
    appointment.isComplete ||
    (appointment.isPast && appointment.isMissed) ||
    appointment.byPhone
  )
    return null;

  return (
    <div className="video-setup-container">
      <div className="today">
        <img src={videoIcon} className="icon" />
        {'How to set up for the video appointment'}
      </div>
      <div className="content">
        <ul className="appointment-help">
          <li>
            <b className="title">Download Zoom</b>
            <p className="description">
              You can download the Zoom Desktop Client for &nbsp;<a
                target="_blank"
                href={'https://zoom.us/client/latest/zoomusInstaller.pkg'}>
                Mac
              </a>, &nbsp;<a
                target="_blank"
                href={'https://zoom.us/client/latest/ZoomInstaller.exe'}>
                Windows
              </a>, &nbsp;<a
                target="_blank"
                href={
                  'https://chrome.google.com/webstore/detail/zoom/hmbjbjdpkobdjplfobhljndfdfdipjhg?hl=en'
                }>
                ChromeOS
              </a>{' '}
              and &nbsp;<a target="_blank" href={'https://zoom.us/download?os=linux'}>
                Linux
              </a>, as well as the Zoom Mobile App for &nbsp;<a
                target="_blank"
                href={'https://itunes.apple.com/us/app/id546505307'}>
                iOS
              </a>{' '}
              and &nbsp;<a target="_blank" href={'https://zoom.us/client/latest/zoom.apk'}>
                Android
              </a>.
            </p>
          </li>
          <li>
            <b className="title">Start a test meeting</b>
            <p className="description">
              You can{' '}
              <a target="_blank" href={'http://zoom.us/test'}>
                join a test Zoom meeting
              </a>{' '}
              to familiarize yourself with the Zoom and test your microphone/speakers before
              joining a Zoom meeting.
            </p>
          </li>
          <li>
            <b className="title">Join consultation</b>
            <p className="description">
              When you are within 15 minutes of the appointment, a button will appear to launch
              the video.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );

}

export default SetupVideoAppointment;
