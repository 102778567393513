import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import InputField from 'components/forms/controls/V2/InputField';
import SelectField from 'components/forms/controls/V2/SelectField';
import validPostalCode from 'postcode-validator';

import { isEmpty, required } from 'services/validate';
import track, { TR_PAYMENT_SUBMIT } from 'services/track';
import { HOME, getCountryStates, getCountriesOption } from 'services/utils';
import FilledCardBlock from './FilledCardBlock';
import { PAYMENTBYINSURANCE } from '../../../../../constants/CommonConstants';
import CouponApplierForm from '../CouponApplierForm/CouponApplierForm';
import Alert from 'components/widgets/Alert/Alert';
import { hydrateHomeAddressFields } from '../../../../../services/api/transformers/addressTransformers';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../../../../../containers/pages/patient/SCP/ProfilePage/CheckoutForm';
import { setSubmitFailure } from '../../../../../ducks/registrationWizard';
import { closeLoader, showLoader } from '../../../../../ducks/ui';
import schedulingService from '../../../../../services/api/schedulingService';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const SCP_PAYMENT_FORM_NAME = 'scp-payment-information-form';

const validateCountryRequired = required('Country');
const validateAddressLine1 = required('Address Line 1');
const validateCity = required('City');

const validate = (values) => {
  const errors = {};
  const zipCodeLabel = values.addressCountry === 'CA' ? 'Postal Code' : 'Zip Code';
  const StateLabel = values.addressCountry === 'CA' ? 'Province' : 'State';

  if (isEmpty(values.addressState)) {
    errors.addressState = `${StateLabel} is required`;
  }
  if (isEmpty(values.addressZipCode)) {
    errors.addressZipCode = `${zipCodeLabel} is required`;
  } else if (!validPostalCode.validate(values.addressZipCode, values.addressCountry)) {
    errors.addressZipCode = `${zipCodeLabel} is invalid`;
  }
  return errors;
};

const PaymentForm = (props) => {
  const {
    newPrice,
    onSkip,
    allAddress,
    user,
    dispatch,
    onSubmit,
    handlePaymentMethod,
    initialValues,
    initialize,
    insurance,
    companies,
    untouch,
    paymentby,
    CountriesStateEnums: { countries, states },
    isMobile,
    submitting,
    handleSubmit,
    toPreviousStep,
    formError,
    valid,
    getStripeIntent,
  } = props;

  const { stripeIntent, stripeIntentLoading } = user;

  const [updatingCard, setUpdatingCard] = useState(false);
  const [initialState, setInitialState] = useState({});
  const [validateCreditCard, setValidateCreditCard] = useState(false);
  const [stripe, setStripe] = useState(undefined);
  const [elements, setElements] = useState(undefined);

  useEffect(() => {
    if (initialValues) {
      setInitialState(initialValues);
    }
    getStripeIntent();
  }, []);

  const handleComponentState = (label, value) => {
    if (label == 'elements') {
      setElements(value);
    } else if (label == 'stripe') {
      setStripe(value);
    } else if (label == 'validateCreditCard') {
      setValidateCreditCard(value);
    }
  };

  const onFormSubmit = async (data) => {
    const isZeroPrice = newPrice === 0;
    const paymentInfo = getPaymentInfo();
    if (!isZeroPrice && (_.isEmpty(paymentInfo) || updatingCard)) {
      await handlePaymentSubmit(data);
    } else {
      await onSkip();
    }
  };

  const setAddress = () => {
    let newAddress = [...allAddress];
    let homeAddress = hydrateHomeAddressFields({
      ...initialState,
      fullName: _.get(user, ['me', 'fullName'], ''),
    });

    const oldHomeAddressIndex = newAddress.findIndex(
      (el) => el.addressType && el.addressType.includes(HOME)
    );
    if (oldHomeAddressIndex > -1) {
      const oldAddressObj = newAddress[oldHomeAddressIndex];
      let oldType = [..._.get(oldAddressObj, 'addressType', [])];
      if (oldType.length > 1) {
        let homeIndex = oldType.indexOf(HOME);
        if (homeIndex > -1) {
          oldType.splice(homeIndex, 1);
          newAddress[oldHomeAddressIndex] = { ...oldAddressObj, addressType: oldType };
          newAddress.push({ ...homeAddress, addressType: [HOME], addressId: undefined });
        }
      } else {
        newAddress[oldHomeAddressIndex] = {
          ...homeAddress,
          addressType: [HOME],
        };
      }
    } else {
      newAddress.push({ ...homeAddress, addressType: [HOME], addressId: undefined });
    }
    return newAddress;
  };

  const handlePaymentSubmit = async (data) => {
    const addresses = setAddress();
    await onSubmit({
      addresses: addresses,
      paymentInfo: {
        ...data,
        cardholderName: user.me.fullName,
      },
    }).then((res) => {
      if (stripe) {
        dispatch(showLoader());
        stripe
          .confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
              payment_method_data: {},
            },
          })
          .then(function (result) {
            if (result.error) {
              dispatch(setSubmitFailure(result.error.message));
              dispatch(closeLoader());
            } else {
              handlePaymentMethod({
                ...data,
                setupIntentId: _.get(user, ['stripeIntent', 'id']),
                addressId: _.get(res, 'addressId', ''),
              });
            }
          });
      }
    });
  };

  const updateCard = () => {
    setUpdatingCard(true);
    setInitialState(initialValues);
    initialize({ ...initialState });
  };

  const insurancePrice = (() => {
    const insuranceCompany = companies.find(
      (c) => c.id === insurance?.primaryInsurance?.insuranceCompanyId
    );
    return insuranceCompany ? insuranceCompany.price : '';
  })();

  const handleChange = (value, key) => {
    setInitialState((preState) => {
      return {
        ...preState,
        [key]: value,
      };
    });
  };

  const handleCountryChange = (value, key) => {
    if (initialState.addressCountry !== value) {
      let obj = {
        [key]: value,
        addressLine1: '',
        addressLine2: '',
        addressCity: '',
        addressState: '',
        addressZipCode: '',
      };
      if (initialValues?.addressCountry == value) {
        obj['addressLine1'] = _.get(initialValues, ['addressLine1'], '');
        obj['addressLine2'] = _.get(initialValues, ['addressLine2'], '');
        obj['addressCity'] = _.get(initialValues, ['addressCity'], '');
        obj['addressState'] = _.get(initialValues, ['addressState'], '');
        obj['addressZipCode'] = _.get(initialValues, ['addressZipCode'], '');
      }
      setInitialState((preState) => {
        return {
          ...preState,
          ...obj,
        };
      });
      untouch('addressLine1');
      untouch('addressLine2');
      untouch('addressCity');
      untouch('addressState');
      untouch('addressZipCode');
      initialize({
        ...initialState,
      });
    }
  };

  const getPaymentInfo = () => {
    let paymentInfo = { ...initialValues };
    delete paymentInfo['addressLine1'];
    delete paymentInfo['addressLine2'];
    delete paymentInfo['addressState'];
    delete paymentInfo['addressCity'];
    delete paymentInfo['addressZipCode'];
    delete paymentInfo['addressCountry'];
    delete paymentInfo['addressFullName'];
    delete paymentInfo['addressId'];
    delete paymentInfo['addressType'];
    delete paymentInfo['addressUUID'];

    return paymentInfo;
  };

  const btDisabled = (submitting, valid) => {
    const paymentInfo = getPaymentInfo();
    const isZeroPrice =
      newPrice === 0 || (insurance && insurancePrice === 0 && paymentby === PAYMENTBYINSURANCE);
    const isCardFilled = !updatingCard && !_.isEmpty(paymentInfo);
    if (isCardFilled || isZeroPrice) {
      return submitting || !valid;
    } else {
      return submitting || !valid || !validateCreditCard;
    }
  };

  const payWithInsurance = () => {
    return !_.isEmpty(insurance) && schedulingService.getUseInsurance();
  };

  const clientSecret = _.get(stripeIntent, ['secret']);
  const allowedCountries = _.get(user, ['me', 'allowedCountries'], ['US']);
  const countryIsNonUS = _.get(user, ['me', 'countryIsNonUS'], false);
  const options = {
    clientSecret: clientSecret,
    appearance: {
      variables: {
        fontFamily: "'RionaSans', Arial, Helvetica, sans-serif",
        spacingUnit: '6px',
      },
      rules: {
        '.Label': {
          fontSize: '12px',
          textTransform: 'capitalize',
        },
        '.Input': {
          padding: isMobile ? '11px 8px' : '8px 8px',
          fontSize: '13px',
          border: '1px solid #bac3ce',
        },
        '.Input:focus': {
          borderColor: '#70cbf1',
        },
        '.Input--invalid': {
          backgroundColor: 'rgba(254, 145, 137, 0.5)',
          boxShadow: 'none',
        },
        '.Error': {
          fontSize: '12px',
          color: '#ff0000',
        },
      },
    },
  };

  const paymentInfo = getPaymentInfo();
  const isCardFilled = !updatingCard && !submitting && !_.isEmpty(paymentInfo);
  const isZeroPrice =
    newPrice === 0 || (insurance && insurancePrice === 0 && paymentby === PAYMENTBYINSURANCE);
  const showOutNetworkBlock = paymentby === PAYMENTBYINSURANCE && insurancePrice > 0;
  const StateLabel = initialState.addressCountry === 'CA' ? 'Province' : 'State';
  const zipCodeLabel = initialState.addressCountry === 'CA' ? 'Postal Code' : 'Zip Code';

  return (
    <Fragment>
      <form
        className="scp-payment-personal-form"
        onSubmit={handleSubmit(onFormSubmit) || track(TR_PAYMENT_SUBMIT)}
      >
        <div className="payment-sections">
          <div className="payment-section">
            {isZeroPrice && (
              <Fragment>
                <div className="no-payment-section">
                  <div className="section-header">Credit or Debit Card</div>
                  <div className="not-required-text marg-btm-32">Not required</div>
                  <div className="section-header">Billing Address</div>
                  <div className="not-required-text">Not required</div>
                </div>
              </Fragment>
            )}
            {!(isCardFilled || isZeroPrice) && (
              <Fragment>
                <Alert message={formError} />
                <div className="card-section">
                  <div className="section-header">Credit or Debit Card</div>
                  {!stripeIntentLoading && (
                    <div className="form-row">
                      <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm
                          handleComponentState={handleComponentState}
                          defaultValues={{
                            billingDetails: {
                              name: _.get(user, ['me', 'fullName'], ''),
                              address: {
                                country: initialValues.addressCountry,
                                postal_code: initialValues.addressZipCode,
                              },
                            },
                          }}
                        />
                      </Elements>
                    </div>
                  )}
                </div>
                {!payWithInsurance() && (
                  <div className="bill-add-section">
                    <div className="section-header">Billing Address</div>
                    {countryIsNonUS && (
                      <div className="form-row">
                        <div className="form-col full-col">
                          <div className="label-section required">Country</div>
                          <Field
                            name="addressCountry"
                            component={SelectField}
                            placeholder="Select"
                            options={getCountriesOption(countries, allowedCountries)}
                            validate={[validateCountryRequired]}
                            onChange={(e, value) => handleCountryChange(value, 'addressCountry')}
                          />
                        </div>
                      </div>
                    )}
                    <div className="form-row">
                      <div className="form-col full-col">
                        <div className="label-section required">Address Line 1</div>
                        <Field
                          name="addressLine1"
                          placeholder="123 Main Street"
                          type="text"
                          component={InputField}
                          validate={[validateAddressLine1]}
                          onChange={(e, value) => handleChange(value, 'addressLine1')}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-col full-col">
                        <div className="label-section">Address Line 2</div>
                        <Field
                          name="addressLine2"
                          placeholder="Apt 123"
                          type="text"
                          component={InputField}
                          onChange={(e, value) => handleChange(value, 'addressLine2')}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-col city-col">
                        <div className="label-section required">City</div>
                        <Field
                          name="addressCity"
                          type="text"
                          placeholder="Enter"
                          component={InputField}
                          validate={[validateCity]}
                          onChange={(e, value) => handleChange(value, 'addressCity')}
                        />
                      </div>
                      <div className="form-col state-col">
                        <div className="label-section required">{StateLabel}</div>
                        <Field
                          name="addressState"
                          component={SelectField}
                          placeholder="Select"
                          options={getCountryStates(
                            states,
                            initialState.addressCountry,
                            'code',
                            'code'
                          )}
                          onChange={(e, value) => handleChange(value, 'addressState')}
                          typeahead={true}
                          clearable={true}
                        />
                      </div>
                      <div className="form-col zip-col">
                        <div className="label-section required">{zipCodeLabel}</div>
                        <Field
                          name="addressZipCode"
                          type="text"
                          placeholder="Enter"
                          component={InputField}
                          label="Zipcode"
                          onChange={(e, value) => handleChange(value, 'addressZipCode')}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            )}
            <div className="payment-info-block">
              {isCardFilled && !isZeroPrice && (
                <FilledCardBlock paymentMethod={initialValues} user={user} onUpdate={updateCard} />
              )}
            </div>
          </div>
          <div className="coupon-section">{<CouponApplierForm {...props} />}</div>
        </div>
        <div className="infosection">
          {!(isCardFilled || isZeroPrice) && (
            <section className="holding-aptmnt-container">
              <div className="holding-aptmnt-txt-wrapper">
                <h4>No charge until appointment is completed</h4>
                <p className="holding-aptmnt-text">
                  To hold your appointment, please provide your credit card information. You will
                  NOT be charged until you have completed your appointment. We request your credit
                  or debit card issuer to place an authorization request or hold on your card for
                  $0.50. The primary reason for an authorization request or hold is to validate the
                  card you are using for payment. Please always check the amount of availability on
                  your card in advance of paying by credit or debit card.
                </p>
              </div>
            </section>
          )}

          {isCardFilled && !isZeroPrice && (
            <section className="holding-aptmnt-container">
              <div className="holding-aptmnt-txt-wrapper">
                <h4>No charge until appointment is completed</h4>
                <p className="holding-aptmnt-text">
                  To hold your appointment, please provide your credit card information. You will
                  NOT be charged until you have completed your appointment. We request your credit
                  or debit card issuer to place an authorization request or hold on your card for
                  $0.50. The primary reason for an authorization request or hold is to validate the
                  card you are using for payment. Please always check the amount of availability on
                  your card in advance of paying by credit or debit card.
                </p>
              </div>
            </section>
          )}
          {showOutNetworkBlock && (
            <section className="holding-aptmnt-container">
              <div className="holding-aptmnt-txt-wrapper">
                <h4>Out-of-network benefits</h4>
                <p className="holding-aptmnt-text">
                  If you have out-of-network benefits with your insurance plan, you may be eligible
                  to receive a full or partial refund of your $50 deposit, depending on the outcome
                  of your claim. However, if your insurance denies the claim or applies it toward
                  your deductible/coinsurance, your $50 deposit will be applied toward the balance
                  and you will receive a statement from Genome Medical. You will never pay more than
                  our $250 self-pay rate for your genetic counseling session.
                </p>
              </div>
            </section>
          )}
        </div>
        <div className="action-buttons">
          {toPreviousStep && (
            <button onClick={toPreviousStep} className="btn-back">
              Back
            </button>
          )}
          <button disabled={btDisabled(submitting, valid)} type="submit" className="btn-next">
            Next
          </button>
        </div>
      </form>
    </Fragment>
  );
};

PaymentForm.propTypes = {
  formError: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  insurance: PropTypes.object,
  companies: PropTypes.array,
  loading: PropTypes.bool,
  newPrice: PropTypes.number,
  onSkip: PropTypes.func,
  submitting: PropTypes.bool,
  toPreviousStep: PropTypes.func.isRequired,
  paymentby: PropTypes.string,
  allAddress: PropTypes.array,
  homeAddress: PropTypes.object,
  user: PropTypes.object,
};

export default reduxForm({
  form: SCP_PAYMENT_FORM_NAME,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  validate,
})(PaymentForm);
