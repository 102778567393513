import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import VerifyEmailSent from './VerifyEmailSent';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import './VerifyEmail.scss';
import verifyEmailIcon from './verify-email.svg';
import RBAC from 'components/high-order-components/RBAC/RBAC';

const VerifyEmail = ({verifyEmail:handleVerifyEmail,email,requestEmailSent}) =>{

  const [inProgress,setInProgres]=useState(false);
  const [showModal,setShowModal] =useState(false);

  const onVerifyEmail = () => {
    if (!inProgress) {
      setInProgres(true);
      handleVerifyEmail().then(()=>{
        setInProgres(true);
        setShowModal(true);
      })
    }
  };
  const _showModal = showModal &&requestEmailSent;

  return (
    <div className="resend-confirmation-email">
      <span className="verify-email-badge-icon">!</span>
      <div className="content">
        <div className="left">
          <img className="visual-content" src={verifyEmailIcon} alt="Verify your email" />
        </div>
        <div className="right">
          <div className="verify-text" data-hj-suppress>
            Please verify your email by clicking the confirmation link sent to{' '}
            <span className="email">{email}</span>.
          </div>
          <ButtonV2
            className="resend-email"
            onClick={onVerifyEmail}
            disabled={inProgress}>
            Resend Confirmation Email
          </ButtonV2>
        </div>
      </div>
      {_showModal && (
        <VerifyEmailSent show={_showModal} email={email} closeModal={()=>setShowModal(false)} />
      )}
    </div>
  );

}


VerifyEmail.propTypes = {
  email: PropTypes.string.isRequired,
  verifyEmail: PropTypes.func.isRequired,
  requestEmailSent: PropTypes.bool.isRequired,
};

export default RBAC(['nonScp'], VerifyEmail);
