import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import './Calendar.scss';
import SCPPatientsToolbar from './toolbar/SCPPatientsToolbar';
import gmiLoader from '../../images/gmi-loader.gif';
import NewCalendar from './NewCalendar';

export const MIN_HOUR = 7;
export const MAX_HOUR = 21;

const Calendar = ({
  events,
  date,
  isMobile,
  isShaded,
  onDateChanged,
  isLoading,
  flow,
  isRescheduling,
  toPreviousStep,
  availableDates,
  isLoadingAvailableDates,
  isPreSelectedFlow,
}) => {
  const defaultState = {
    selectedDate: null,
    availableSlots: {
      timesOfDay: [],
    },
    selectedSlot: {},
    isDateSelected: false,
  };

  const [state, setState] = useState(defaultState);

  const getToolbarComponent = () => {
    return SCPPatientsToolbar;
  };

  const updateCalendar = (newState) => {
    new Promise((resolve) => {
      setState((prevState) => ({ ...prevState, ...newState }));
      resolve();
    });
  };

  const clearCalendar = () => {
    new Promise((resolve) => {
      setState({ ...defaultState });
      resolve();
    });
  };

  const { selectedDate, availableSlots, selectedSlot, isDateSelected } = state;
  const ToolbarComponent = getToolbarComponent();
  const slots = events.filter((event) => event.type !== 'NA');
  const showToolBar = isPreSelectedFlow || isRescheduling;

  return (
    <div className="gm-cal-container">
      {showToolBar && (
        <ToolbarComponent
          isArrowVisible={isShaded}
          flow={flow}
          isRescheduling={isRescheduling}
          clearCalendar={clearCalendar}
        />
      )}

      <NewCalendar
        startDate={date}
        slots={slots.length ? slots : []}
        onDateChange={onDateChanged}
        isLoading={isLoading}
        toPreviousStep={toPreviousStep}
        isRescheduling={isRescheduling}
        selectedDate={selectedDate}
        availableSlots={availableSlots}
        selectedSlot={selectedSlot}
        isDateSelected={isDateSelected}
        updateCalendar={updateCalendar}
        clearCalendar={clearCalendar}
        availableDates={availableDates}
        isLoadingAvailableDates={isLoadingAvailableDates}
        isMobile={isMobile}
      />
      {isLoading && <img className="calendar-loader" src={gmiLoader} />}
    </div>
  );
};

Calendar.propTypes = {
  events: PropTypes.array.isRequired,
  date: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isShaded: PropTypes.bool,
  onDateChanged: PropTypes.func,
  isLoading: PropTypes.bool,
  flow: PropTypes.string,
  isRescheduling: PropTypes.bool,
  toPreviousStep: PropTypes.func,
  availableDates: PropTypes.array,
  isLoadingAvailableDates: PropTypes.bool,
  isPreSelectedFlow: PropTypes.bool,
};

export default Calendar;
