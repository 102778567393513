import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from 'components/modals/ModalDialog';
import ModalUploadingForm from 'components/scp/forms/documents/ModalUploadingForm';
import './UploadDocumentsModal.scss';

const UploadDocumentsModal = (props) =>{
  const {show, onCancel, roleModal, modalHeader} = props

  const documentsProps = _.omit(props, ['show', 'onCancel']);
  return(
    <ModalDialog
        modalHeader={modalHeader}
        className="scp-upload-document-dialog scp-448md"
        show={show}
        backdrop="static"
        onHide={onCancel}
        roleModal={roleModal}>
        <div className="scp-uploading-modal" data-hj-suppress>
          <ModalUploadingForm {...documentsProps} />
        </div>
      </ModalDialog>
  )

}

UploadDocumentsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  dropzoneOnDrop: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  uploadingStatus: PropTypes.object.isRequired,
  documentTypes: PropTypes.array,
  initialValues: PropTypes.object,
  roleModal: PropTypes.string,
  modalHeader: PropTypes.string,
};

export default UploadDocumentsModal;
