import _ from 'lodash';

import surveyService from 'services/api/surveyService';
import { FollowUpConsultationType } from '../constants/ServiceTypes';

const SET_SURVEYS = 'gm/answers/SET_SURVEYS';
const SET_COMPLETED = 'gm/answers/SET_COMPLETED';
const LOAD_SURVEY = 'gm/answers/LOAD_SURVEY';
const LOAD_SURVEY_COMPLETE = 'gm/answers/LOAD_SURVEY_COMPLETE';

export default (
  state = {
    surveys: null,
    completed: null,
    loading: false,
    loaded: false,
  },
  action = {}
) => {
  switch (action.type) {
    case SET_SURVEYS:
      return { ...state, surveys: action.payload };
    case SET_COMPLETED:
      return { ...state, completed: action.payload };
    case LOAD_SURVEY:
      return { ...state, loading: true };
    case LOAD_SURVEY_COMPLETE:
      return { ...state, loading: false, loaded: true };
    default:
      return state;
  }
};

export const setCompleted = (isComplete) => ({
  type: SET_COMPLETED,
  payload: isComplete,
});

export const setSurveys = (surveys) => ({
  type: SET_SURVEYS,
  payload: surveys,
});

export const startLoadAnswers = () => ({ type: LOAD_SURVEY });
export const loadComplete = (isNotFound = false) => ({
  type: LOAD_SURVEY_COMPLETE,
  payload: isNotFound,
});

export const loadSurvey = (appointmentId) => async (dispatch) => {
  dispatch(startLoadAnswers());
  const surveys = await surveyService.getSurveys();
  dispatch(setSurveys(surveys));
  if (surveys && surveys.length) {
    const { surveyAlias, token, isSurveyJSSurvey, isZingtreeSurvey, isZingtreeCompleted } =
      surveys[0];
    if (isSurveyJSSurvey && surveyAlias) {
      const results = await surveyService.getSurveyResults(surveyAlias, token);
      let isCompleted = false;
      if (results) {
        isCompleted =
          results.payload &&
          results.payload.__completed &&
          results.payload.__appointmentId == appointmentId;
      }
      dispatch(setCompleted(isCompleted));
    } else if (isZingtreeSurvey) {
      dispatch(setCompleted(isZingtreeCompleted));
    }
  }
  dispatch(loadComplete());
};

export const canShowSurveyQuestionnaire = ({
  appointment: { data, loading, loaded },
  answers: { surveys },
}) => {
  if (!loaded || loading) return false;
  if (_.isEmpty(surveys)) return false;
  if (_.get(surveys, ['0', 'surveyAlias']) === '__NO_SURVEY__') return false;
  return !!(
    !_.isEmpty(data) &&
    data.isFuture &&
    !data.isStartSoon &&
    data.latestStatus &&
    data.latestStatus.status === 'booked' &&
    data.consultation.type !== FollowUpConsultationType &&
    _.get(surveys, ['0', 'isSurveyJSSurvey'], false)
  );
};

export const canShowZingtreeQuestionnaire = ({
  appointment: { data, loading, loaded },
  answers: { surveys },
}) => {
  if (!loaded || loading) return false;
  if (_.isEmpty(surveys)) return false;
  return !!(
    !_.isEmpty(data) &&
    data.isFuture &&
    !data.isStartSoon &&
    data.latestStatus &&
    data.latestStatus.status === 'booked' &&
    data.consultation.type !== FollowUpConsultationType &&
    _.get(surveys, ['0', 'isZingtreeSurvey'], false) &&
    !_.get(surveys, ['0', 'isZingtreeCompleted'], true)
  );
};

export const canShowQuestionnaire = (state) => {
  return canShowSurveyQuestionnaire(state) || canShowZingtreeQuestionnaire(state);
};
