import React from 'react';

import { Link } from 'react-router-dom';
import './PatientCards.scss';
import viewDocsIcon from './view-doc.svg';
import RBAC from 'components/high-order-components/RBAC/RBAC';

const ViewDocument = () =>{
  return (
    <div className="upload-photo-card">
      <div className="content">
        <div className="static">
          <div className="left">
            <div className="title">View and upload your documents</div>
            <div className="text" data-hj-suppress>
              The documents folder contains your summary report, and it’s also where you can
              upload relevant documents — like previous test results — to share with your
              Certified Genetic Counselor.
            </div>
          </div>
          <div className="right">
            <img className="visual-content" src={viewDocsIcon} alt="view docs" />
          </div>
        </div>
        <div className="action-items left">
          <div className="link">
            <Link to="/patient/results">View Documents</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RBAC(['scp'], ViewDocument);
