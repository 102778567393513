import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { testOrderCardTitles, testOrderCardDescriptions } from 'constants/TestOrderConstants';
import RBAC from 'components/high-order-components/RBAC/RBAC';
import './PatientCards.scss';
import testOrderIcon from './test-order.svg';

const TestOrder = ({status,history}) =>{

  const title = testOrderCardTitles[status];
  const description = testOrderCardDescriptions[status];
  return (
    <Fragment>
      {this.title && this.description ? (
        <div className="upcoming-appointment">
          <div
            className="content"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push('/patient/testorder');
            }}
          >
            <div className="static">
              <div className="left">
                <div className="tag">
                  <span className="green">Test Order</span>
                </div>
                <div className="title">{title}</div>
                <div className="text">{description}</div>
              </div>
              <div className="right">
                <img className="visual-content" src={testOrderIcon} alt="Incomeplete Task" />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );

}

TestOrder.propTypes = {
  status: PropTypes.string,
};

export default RBAC(['scp'], withRouter(TestOrder));
