import PropTypes from 'prop-types';
import React, { Component, useEffect } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import get from 'lodash/get';

import './PatientCards.scss';
import verifyEmailIcon from './verify-email.svg';

const VerifyEmailSent = ({closeModal,show,email}) =>{
  useEffect(()=>{
    setTimeout(() => {
      closeModal();
    }, 10000);
  },[]);

  return (
    <Modal bsClass="modal" show={show} role="verify-email-sent">
      <img src={verifyEmailIcon} alt="Verify email sent" />
      <div className="title">Email Sent!</div>
      <div className="text" data-hj-suppress>
        We have sent another email verification to <b>{email}</b>.
      </div>
      <div className="text with-margin">
        If you do not see it, you may need to check your <b>spam folder</b>.
      </div>
      <a href="javascript:void(0)" onClick={closeModal}>
        Go back
      </a>
    </Modal>
  );
}



VerifyEmailSent.propTypes = {
  show: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default VerifyEmailSent;
