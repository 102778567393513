import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import noApptIcon from './no-appt.png';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import './PatientCards.scss';
import { SCH_NEW_APPT_HIGH_PRIORITY } from 'constants/HomeAppointmentCardGoals';

const ScheduleNewAppointment = ({
  goal
}) =>{
  const [followupAptmntExpand, setFolloupAptmntExpand] = useState(goal == SCH_NEW_APPT_HIGH_PRIORITY);
  return (
    <Fragment>
      <div className="followup-appointment-wrapper new-appointment-wrapper">
        <div
          className="header new-schedule-header"
          onClick={() => setFolloupAptmntExpand(followupAptmntExpand)
          }>
          <span>Schedule new appointment</span>
          <img
            src={require('images/common/arrow-down.svg')}
            alt=""
            className="accordian-icon"
            style={{
              transform:
               followupAptmntExpand === true
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
            }}
          />
        </div>
        <div
          className="content"
          style={{ display: followupAptmntExpand === true ? 'block' : 'none' }}>
          <div className="static">
            <div className="left">
              <div className="text">
                You have no upcoming appointments. Our Certified Genetic Counselors can guide you
                in making an informed decision about genetic testing and the implications of those
                results on your health. Schedule a new appointment today!
              </div>
            </div>
            <div className="right">
              <img className="visual-content" src={noApptIcon} alt="no appointment" />
            </div>
          </div>
          <div className="action-items left">
            <ButtonV2
              path={"/scheduling/select-service"}
              className={
                goal == SCH_NEW_APPT_HIGH_PRIORITY ? '' : 'outline'
              }>
              Schedule New Appointment
            </ButtonV2>
          </div>
        </div>
      </div>
    </Fragment>
  );
}


ScheduleNewAppointment.propTypes = {
  goal: PropTypes.bool,
};

export default ScheduleNewAppointment;
