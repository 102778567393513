import PropTypes from 'prop-types';
import React from 'react';

import Calendar from 'components/calendar/Calendar';
import { CreateEmptySlotEvents } from 'components/calendar/CalendarUtils';
import './AppointmentCalendar.scss';

const AppointmentCalendar = ({
  onSlotClick,
  date,
  slots,
  reservations,
  isShaded,
  isLoading,
  onDateChanged,
  isMobile,
  isRescheduling,
  toPreviousStep,
  availableDates,
  isLoadingAvailableDates,
  isPreSelectedFlow,
}) => {
  const slotEvents = CreateEmptySlotEvents({
    eventsList: slots,
    reservations,
    onSlotClick: onSlotClick,
    startDate: date,
  });

  return (
    <Calendar
      availableDates={availableDates}
      events={slotEvents}
      date={date}
      onDateChanged={onDateChanged}
      isShaded={isShaded}
      isLoading={isLoading}
      isMobile={isMobile}
      flow="scp"
      isRescheduling={isRescheduling}
      toPreviousStep={toPreviousStep}
      isLoadingAvailableDates={isLoadingAvailableDates}
      isPreSelectedFlow={isPreSelectedFlow}
    />
  );
};

AppointmentCalendar.propTypes = {
  slots: PropTypes.array.isRequired,
  reservations: PropTypes.array.isRequired,
  onSlotClick: PropTypes.func.isRequired,
  isShaded: PropTypes.bool,
  date: PropTypes.object.isRequired,
  onDateChanged: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  toPreviousStep: PropTypes.func.isRequired,
};

export default AppointmentCalendar;
