import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classnames from 'classnames';
import './Toolbar.scss';

const Toolbar = ({ left }) => {
  return (
    <Fragment>
      <div className="location-info">Enter Location Info</div>
      <div className={classnames('calendar-toolbar v2')}>
        <div className="left">{left}</div>
      </div>
    </Fragment>
  );
};

Toolbar.propTypes = {
  left: PropTypes.node,
};

export default Toolbar;
