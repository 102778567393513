import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { canShowZingtreeQuestionnaire } from 'ducks/answers';
import './PatientCards.scss';
import { CurbsideConsultation, CURBSIDE_15_CONSULATION_DURATION } from 'constants/ServiceTypes';
import checklistIcon from './pre-appt-checklist.png';
import RBAC from 'components/high-order-components/RBAC/RBAC';

export const ZingtreeQuestionnaire = ({ appointment, isReviewQn, showQuestionnaire }) => {
  const { consultation, startTime, endTime, timezoneAbb, mStartTime, mEndTime } = appointment

  const formattedDateAndTime = () => {
    const formattedStart =
      mStartTime.format('mm') === '00' ? mStartTime.format('h') : mStartTime.format('h:mm');

    const correctedEnd =
      consultation.type === CurbsideConsultation
        ? mStartTime.add(CURBSIDE_15_CONSULATION_DURATION, 'minutes')
        : mEndTime;
    const formattedEnd =
      moment(correctedEnd).format('mm') === '00'
        ? moment(correctedEnd).format('h A')
        : moment(correctedEnd).format('h:mm A');

    return `${formattedStart}-${formattedEnd} ${timezoneAbb}`;
  }

  const text = 'Ready to make the most of your appointment? Complete this 10-15 minute genetics primer and survey for a more personalized one-on-one session.';
  const type = <span className="blue">Incomplete Task</span>;
  const title = 'Complete your pre-appointment journey';
  const actionButton = (
    <div className="link">
      <Link to="/patient/questionnaire">Launch your journey</Link>
    </div>
  );

  if (_.isEmpty(appointment) || !showQuestionnaire) return null;

  return (
    <div className="upcoming-appointment">
      <div className="content">
        <div className="static">
          <div className="left">
            <div className="tag">{type}</div>
            <div className="title">{title}</div>
            <div className="time" data-hj-suppress>
              {`${consultation.name} ${formattedDateAndTime()}`}
            </div>
            <div className="text" data-hj-suppress>
              {text}
            </div>
          </div>
          <div className="right">
            <img className="visual-content" src={checklistIcon} alt="upload your card" />
          </div>
        </div>
        <div className="action-items left">{actionButton}</div>
      </div>
    </div>
  )

}


ZingtreeQuestionnaire.propTypes = {
  appointment: PropTypes.object,
  appointmentLoaded: PropTypes.bool,
  isReviewQn: PropTypes.bool,
  showQuestionnaire: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    appointment: state.appointment.data,
    appointmentLoaded: state.appointment.loaded && !state.appointment.loading,
    showQuestionnaire: canShowZingtreeQuestionnaire(state),
  };
};

export default RBAC(['nonScp'], connect(mapStateToProps)(ZingtreeQuestionnaire));
