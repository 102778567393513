import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { orderStatuses } from 'constants/TestOrderConstants';
import SuccessIcon from './success.svg';
import WarningIcon from './warning.svg';
import FailureIcon from './failure.svg';
import './TestInfoBox.scss';

const TestInfoBox = ({ encounter, geneticTestLab, geneticTestLoading, className, history, type }) => {
  if (!encounter || !encounter.order) return null
  const { createdAt, order, isTROEncounter, testName, labName } = encounter;
  const { dateTestAuthorized, dateTestOrdered, status } = order;

  const mapper = {
    [orderStatuses.awaitingApproval]: {
      statusText: isTROEncounter ? 'Awaiting Review' : 'Awaiting Approval',
      dateDescription: 'Requested on',
    },
    [orderStatuses.approved]: {
      statusText: 'Approved',
      dateDescription: isTROEncounter ? 'Authorized on' : 'Approved Date',
    },
    [orderStatuses.sentToLab]: {
      statusText: 'Sent to Lab',
      dateDescription: isTROEncounter ? 'Lab order placed on' : 'Date Test Ordered',
    },
    [orderStatuses.resultsReady]: {
      statusText: 'Results Ready',
      dateDescription: isTROEncounter ? 'Test ordered on' : 'Date Test Ordered',
    },
    [orderStatuses.cancelled]: {
      statusText: 'Order Canceled',
      dateDescription: 'We will contact you for next steps',
    },
    [orderStatuses.null]: {
      statusText: null,
      dateDescription: null,
    },
  };

  const warningStatus = (() => {
    const allowedStatus = [orderStatuses.awaitingApproval];
    const tempStatus = status === null ? JSON.stringify(status) : status;
    if (allowedStatus.indexOf(tempStatus.toLowerCase()) !== -1) {
      const { statusText, dateDescription } = mapper[tempStatus];
      return (
        <Fragment>
          <div className="status warning" data-hj-suppress>
            <img src={WarningIcon} alt="Warning" /> {statusText}
          </div>
          {status == orderStatuses.awaitingApproval && isTROEncounter && (
            <Fragment>
              <div className="date-description mgt32px">{dateDescription}</div>
              <div className="date-populated">
                {(moment(createdAt).isValid() && moment(createdAt).format('MMMM D, YYYY')) || ''}
              </div>
            </Fragment>
          )}
        </Fragment>
      );
    } else return null;
  })();

  const successStatus = (() => {
    const allowedStatus = [
      orderStatuses.approved,
      orderStatuses.sentToLab,
      orderStatuses.resultsReady,
    ];

    const tempStatus = status === null ? JSON.stringify(status) : status;

    if (allowedStatus.indexOf(tempStatus.toLowerCase()) !== -1) {
      let orderDate = tempStatus === orderStatuses.approved ? dateTestAuthorized : dateTestOrdered;
      const { statusText, dateDescription } = mapper[tempStatus];
      return (
        <Fragment>
          <div className="status success" data-hj-suppress>
            <img src={SuccessIcon} alt="Success" /> {statusText}
          </div>
          <div className="date-description">
            {status === orderStatuses.approved && <img src={SuccessIcon} alt="Success" />}{' '}
            {dateDescription}
          </div>
          <div className="date-populated">
            {(moment(orderDate).isValid() && moment(orderDate).format('MMMM D, YYYY')) || ''}
          </div>
        </Fragment>
      );
    } else return null;
  })();

  const failureStatus = (() => {
    const allowedStatus = [orderStatuses.cancelled];
    const tempStatus = status === null ? JSON.stringify(status) : status;
    if (allowedStatus.indexOf(status.toLowerCase()) !== -1) {
      const { statusText, dateDescription } = mapper[tempStatus];
      return (
        <Fragment>
          <div className="status failure" data-hj-suppress>
            <img src={FailureIcon} alt="Failure" /> {statusText}
          </div>
          <i>{isTROEncounter && orderStatuses.cancelled == status ? '' : dateDescription}</i>
        </Fragment>
      );
    } else return null;
  })();

  const topHalf = () => {
    let displayTestName = testName;
    if (_.get(geneticTestLab, ['patient_display_name']))
      displayTestName = _.get(geneticTestLab, ['patient_display_name'], testName);
    const tempStatus = status === null ? JSON.stringify(status) : status;
    const { statusText, dateDescription } = mapper[tempStatus];

    return (
      <Fragment>
        {statusText && dateDescription ? (
          <div className="top-half" data-hj-suppress>
            <div className="test-name">{displayTestName}</div>
            <div className="lab-name">{labName}</div>
          </div>
        ) : (
          <div className="no-orders">There are no requested tests</div>
        )}
      </Fragment>
    );
  }

  const bottomHalf = () => {
    const tempStatus = status === null ? JSON.stringify(status) : status;
    const { statusText, dateDescription } = mapper[tempStatus];

    return (
      <Fragment>
        {statusText && dateDescription && (
          <div className="bottom-half">
            <div className="status-text">Status</div>
            {warningStatus || successStatus || failureStatus}
            {!isTROEncounter && (
              <div className="footer">
                <Link to="/patient/testorder">View Details</Link>
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  }

  const renderTestInfo = () => {
    if (
      _.isNil(encounter) ||
      _.get(encounter, ['isAppointmentSubType']) === true ||
      geneticTestLoading
    ) {
      return null;
    }

    const tempStatus = status === null ? JSON.stringify(status) : status;
    if (isTROEncounter && tempStatus == orderStatuses.rejected) {
      return null;
    }



    if (tempStatus && mapper[status]) {
      const { statusText, dateDescription } = mapper[tempStatus];
      const isClickable = !isTROEncounter && statusText && dateDescription;


      return (
        <div className={classnames('scp-test-info-box', className)}>
          <div
            className="content"
            style={{ cursor: isClickable ? 'pointer' : 'auto' }}
            onClick={() => {
              if (isClickable) history.push('/patient/testorder');
            }}
          >
            <div className="header">Test Status</div>
            {topHalf()}
            {bottomHalf()}
          </div>
        </div>
      );
    }

    return null;
  }

  return <Fragment>{type == 'test' && renderTestInfo()}</Fragment>;

}

TestInfoBox.propTypes = {
  className: PropTypes.string,
  encounter: PropTypes.object,
  type: PropTypes.string,
};

export default TestInfoBox;
