import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get, isEmpty, head } from 'lodash';
import classnames from 'classnames';
import schedulingService from 'services/api/schedulingService';
import _ from 'lodash';
import SelectedServiceDetail from '../scp/scheduling/AppointmentDetails/SelectedServiceDetail';

import './NewCalendar.scss';
import DaySelect from './DaySelect';

const NewCalendar = ({
  isMobile,
  startDate,
  isLoading,
  slots,
  updateCalendar,
  availableSlots,
  selectedSlot,
  isRescheduling,
  selectedDate,
  toPreviousStep,
  onDateChange,
  clearCalendar,
}) => {
  useEffect(() => {
    if (!isLoading) {
      const avaialeSlots = getAvailableSlots();
      const dates = Object.keys(avaialeSlots).sort(
        (a, b) => moment(a).valueOf() - moment(b).valueOf()
      );
      if (!isEmpty(dates)) updateSlots(moment(head(dates)));
    }
  }, [isLoading]);

  const getAvailableSlots = () => {
    let propsSlots = (slots || []).filter((slot) => !moment(slot.start).isSame(slot.end));
    let _slots = {};
    let date;
    for (const slot of propsSlots) {
      if (
        moment(slot.start).isBetween(startDate, moment(startDate).add(7, 'days')) &&
        moment(slot.start).isBetween(
          moment(slot.start).set({ hour: 6, minute: 59, second: 0, millisecond: 0 }),
          moment(slot.start).set({ hour: 21, minute: 0, second: 0, millisecond: 0 })
        )
      ) {
        date = moment(slot.start).format('YYYY-MM-DD');
        if (!_slots[date]) _slots[date] = [slot];
        else _slots[date].push(slot);
      }
    }

    return _slots;
  };

  const filterDates = (date) => {
    const avaialeSlots = getAvailableSlots();

    if (isLoading) return false;

    const dates = Object.keys(avaialeSlots);
    const calendarDate = moment(date).format('YYYY-MM-DD');

    return dates.indexOf(calendarDate) !== -1;
  };

  const createTimePeriodsSlot = (slots, date) => {
    const compareDate = date.clone();
    const timesOfDay = [];
    for (const slot of slots) {
      if (
        moment(slot.start).isBetween(
          moment(compareDate).set({ hour: 6, minute: 59, second: 0, millisecond: 0 }),
          moment(compareDate).set({ hour: 21, minute: 0, second: 0, millisecond: 0 })
        )
      )
        timesOfDay.push(slot);
    }
    return {
      timesOfDay: timesOfDay.sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf()),
    };
  };

  const updateSlots = (date) => {
    const _slots = getAvailableSlots();
    const currentDate = moment(date).format('YYYY-MM-DD');
    const availableSlots = createTimePeriodsSlot(_slots[currentDate], date);
    updateCalendar({
      selectedDate: moment(date),
      availableSlots: availableSlots,
      selectedSlot: {},
      isDateSelected: true,
    });
  };

  const renderSlots = () => {
    const timesOfDay = get(availableSlots, ['timesOfDay'], []);
    if (isEmpty(timesOfDay) && !isLoading) {
      return (
        <div className="slots-container no-slots">
          There are no available appointments on the date you selected. Please pick another date to
          view available slots.
        </div>
      );
    }

    return (
      <div className="slots-container">
        {!isEmpty(timesOfDay) && (
          <div>
            <div className="header">Choose a Time</div>
            <div className="slots">
              {timesOfDay.map((selSlot, i) => (
                <div
                  key={`${moment(selSlot.start).valueOf()}-${i}`}
                  className={classnames({
                    selected:
                      moment(selSlot.start).valueOf() === moment(selectedSlot.start).valueOf(),
                  })}
                  onClick={() => updateCalendar({ selectedSlot: selSlot })}
                >
                  {moment(selSlot.start).format('h:mm A')}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleMonthChange = async (date) => {
    await clearCalendar();
    const _startDate = schedulingService.getStartOfMonth(date);
    onDateChange(_startDate);
  };

  const handleNext = async (date) => {
    await clearCalendar();
    const _startDate = moment(date).toDate();
    onDateChange(_startDate);
  };

  const handlePrevious = async (date) => {
    await clearCalendar();
    const _startDate = moment(date).toDate();
    onDateChange(_startDate);
  };

  const goNext = () => {
    const { start, end, sid, onSlotClick } = selectedSlot;
    onSlotClick(sid, start, end);
  };

  const _slots = getAvailableSlots();
  return (
    <div className="new-calendar-container">
      <DaySelect
        startDate={startDate}
        selectedDate={selectedDate}
        updateSlots={updateSlots}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        slots={_slots}
      />

      <div className="calendar-group">{renderSlots()}</div>
      <SelectedServiceDetail
        isMobile={isMobile}
        selectedSlot={selectedSlot}
        selectedDate={selectedDate}
      />
      <div className="action-buttons">
        {toPreviousStep && (
          <button onClick={toPreviousStep} disabled={isLoading} className="btn-back">
            Back
          </button>
        )}
        <button onClick={goNext} disabled={isLoading || isEmpty(selectedSlot)} className="btn-next">
          {isRescheduling ? 'Confirm' : 'Next'}
        </button>
      </div>
    </div>
  );
};

NewCalendar.propTypes = {
  startDate: PropTypes.isRequired,
  slots: PropTypes.array.isRequired,
  onDateChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  toPreviousStep: PropTypes.func.isRequired,
  isRescheduling: PropTypes.bool.isRequired,
  selectedDate: PropTypes.isRequired,
  availableSlots: PropTypes.object.isRequired,
  selectedSlot: PropTypes.object.isRequired,
  isDateSelected: PropTypes.bool.isRequired,
  updateCalendar: PropTypes.func.isRequired,
};

export default NewCalendar;
