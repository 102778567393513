import React, { Fragment } from 'react';
import _ from 'lodash';
import biopharmaIcon from './biopharma.png';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import './PatientCards.scss';

const BioPharmaTrailAuthorization = () =>{

  return (
    <Fragment>
       <div className="biopharma-authorization">
      <div className="content mb15">
        <div className="static">
          <div className="left">
            <div className="title">Patient Authorization For Recontact</div>
            <div className="text" data-hj-suppress>
              While Genome Medical has certain rights under HIPAA to use your data to provide care and for research purposes, we may also contact you for the purposes of clinical trials, treatments or products that may be relevant to your health condition.
            </div>
          </div>
          <div className="right">
            <img className="biopharma-img" src={biopharmaIcon} alt="no appointment" />
          </div>
        </div>
        <div className="action-items left">
          <ButtonV2 path="/patient/consent" className="no-appointment">
          Authorize
          </ButtonV2>
        </div>
      </div>
    </div>
    </Fragment>
  );

}

export default BioPharmaTrailAuthorization;
