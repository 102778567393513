import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Select from 'components/forms/controls/Select';

import { selectStateByUser, selectTimezoneByUser, selectCountryByUser } from 'ducks/scheduling';
import Button from 'components/forms/controls/Button';
import './AppointmentServiceOptionsV2.scss';
import classnames from 'classnames';
import _ from 'lodash';
import { GetStateWiseTimezone } from 'services/geo';

import ButtonV2 from 'components/forms/controls/ButtonV2';

import 'components/forms/controls/IconModalPopup.scss';
import { getCountriesOption } from '../../../../../services/utils';

const AppointmentServiceOptions = ({
  dispatch,
  scheduling,
  user,
  isMobile,
  setShowSpeciality,
  isSpecialtySelected,
  selected,
  selectedSpecialty,
  onFinalPurchaseClick,
  CountriesStateEnums: { states, countries },
}) => {
  const onCountryChanged = (e) => {
    dispatch(selectCountryByUser(e));
  };
  const onStateChanged = (e) => {
    dispatch(selectStateByUser(e));
  };

  const onTimezoneChanged = (e) => {
    dispatch(selectTimezoneByUser(e));
  };

  const isTimeZoneSelectShaded = () => {
    return !scheduling.calendarState;
  };

  const renderOptionsWithoutIcon = (props) => {
    return (
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <span style={{ float: 'left', width: '95%' }}>{props.display_name}</span>
      </div>
    );
  };

  const getCountryStates = (country) => {
    const selectedCountryStates = (states || []).filter((a) => a.countryCode == country);
    return selectedCountryStates.map((state) => {
      return {
        label: state.name,
        value: state.code,
        key: state.code,
      };
    });
  };

  const renderCanadaLanguage = () => {
    return (
      <div className="canada-language">
        <div className="canada-title"> Canadian Restriction</div>
        <div className="description">
          <p>
            Genome Medical provides genetic counseling services to patients in the U.S. and Canada.
            Due to licensing restrictions, our board-certified, U.S.-based physicians are unable to
            order genetic tests for patients who reside outside of the United States.
          </p>
          <br />
          <p>
            Please continue this registration process to meet with one of our licensed genetic
            counselors who will be happy to help you understand the genetic testing process and
            possible tests that could be coordinated through your Canadian primary care provider, or
            to review any genetic test results you have already received.
          </p>
        </div>
      </div>
    );
  };

  const { me } = user;
  const { partnerSpecialties } = me;
  const { calendarState, calendarTimezone, calendarCountry } = scheduling;
  const disabled = !calendarState || !calendarTimezone;
  const timezones = GetStateWiseTimezone(calendarState, calendarCountry);
  const disabledTimezone = timezones && timezones.length <= 1;
  const isCalendarTimeZoneDisabled = isTimeZoneSelectShaded() || disabledTimezone;
  const allowedCountries = _.get(user, ['me', 'allowedCountries'], ['US']);
  const hasSpecialtySelected = isSpecialtySelected && isSpecialtySelected == selected;
  const countryIsNonUS = _.get(user, ['me', 'countryIsNonUS'], false);

  return (
    <>
      <div className="scp-appointment v2">
        <div className="scp-appointment-service-options">
          {countryIsNonUS && (
            <div className="select-country">
              <p className="title">Country at Time of Visit</p>
              <div className={classnames('select-wrapper', 'input-field')}>
                <Select
                  value={calendarCountry}
                  placeholder={'Selecrt...'}
                  options={getCountriesOption(countries, allowedCountries) || []}
                  onChange={onCountryChanged}
                />
              </div>
            </div>
          )}

          <div className="select-state">
            <p className="title">
              {calendarCountry == 'CA' ? 'Province at Time of Visit' : 'State at Time of Visit'}
            </p>
            <div className={classnames('select-wrapper', 'input-field')}>
              <Select
                value={calendarState}
                placeholder={'AL'}
                options={getCountryStates(calendarCountry) || []}
                onChange={onStateChanged}
                typeahead={true}
                clearable={true}
              />
            </div>
          </div>

          <div className="select-timezone">
            <p className="title">Time Zone at Time of Visit</p>
            <div className={classnames('select-wrapper', 'input-field')}>
              <Select
                value={calendarTimezone}
                placeholder={'Select your timezone'}
                options={timezones || []}
                disabled={isCalendarTimeZoneDisabled}
                onChange={onTimezoneChanged}
              />
            </div>
          </div>
          <div className="select-speciality">
            <div className="title">Specialty</div>
            <div className={classnames('select-wrapper', 'input-field')}>
              {!selectedSpecialty ? (
                <ButtonV2 className="select-speciality-btn" onClick={() => setShowSpeciality(true)}>
                  Select a specialty
                </ButtonV2>
              ) : (
                <div className="input-icon-container">
                  <Select
                    value={selectedSpecialty}
                    placeholder={'Select...'}
                    options={partnerSpecialties.map((el) => {
                      return { ...el, label: el.display_name, value: el.name };
                    })}
                    renderOption={(el) => renderOptionsWithoutIcon(el)}
                    disabled={true}
                  />
                  <ButtonV2
                    className="select-speciality-btn edit"
                    onClick={() => setShowSpeciality(true)}
                  >
                    Edit Specialty
                  </ButtonV2>
                </div>
              )}
            </div>
          </div>
        </div>
        {isMobile && calendarCountry == 'CA' && renderCanadaLanguage()}
        {isMobile && (
          <div className="btn-schedule-container">
            <Button
              type="submit"
              onClick={onFinalPurchaseClick}
              disabled={disabled || !hasSpecialtySelected}
              className="btn-schedule-appointment"
            >
              {'Next'}
            </Button>
          </div>
        )}
        <div className="service-help">
          Still unsure about which specialty to choose? Call{' '}
          <a href="tel:+18776880992">(877) 688-0992</a> to be scheduled with the right provider.
        </div>
      </div>
      {!isMobile && calendarCountry == 'CA' && renderCanadaLanguage()}
    </>
  );
};

const mapStateToProps = (state) => ({
  scheduling: state.scheduling,
  user: state.user,
  CountriesStateEnums: _.get(state, ['enums', 'countryState', 'data'], {}),
});

AppointmentServiceOptions.propTypes = {
  scheduling: PropTypes.object.isRequired,
  isArrowVisible: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

export default connect(mapStateToProps)(AppointmentServiceOptions);
