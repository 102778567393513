import React, { Component, useEffect } from 'react';
import gaTrack, { GA_TR_SCHEDULING_PAYMENT_REVIEW_PAGE } from '../../../../../services/gaTrack';
import CouponApplierForm from './ViewOnlyCouponApplierForm/ViewOnlyCouponApplierForm';
import './ReviewPaymentSummaryForm.scss';

export const PAYMENT_FORM_NAME = 'scp-payment-information-form';

const ReviewPaymentSummaryForm = (props) => {
  const { loading, goNext, toPreviousStep, isLoading } = props;
  useEffect(() => {
    gaTrack(GA_TR_SCHEDULING_PAYMENT_REVIEW_PAGE);
    window.scrollTo(0, 0);
  }, []);

  if (loading) return null;
  return (
    <div className="scp-schedule-wizard scp-payment-review-stage">
      <h2 className="gm-select-service_header">Review Payment Summary</h2>
      <div className="scp-payment-personal-form">
        <div className="coupon-section">
          <CouponApplierForm {...props} />
        </div>
        <div className="action-buttons">
          {true && (
            <button onClick={toPreviousStep} disabled={isLoading} className="btn-back">
              Back
            </button>
          )}
          <button onClick={goNext} className="btn-next">
            {'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewPaymentSummaryForm;
