import PropTypes from 'prop-types';
import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';
import Button from 'components/forms/controls/ButtonV2';

import { findCouponItem, clearSelectedService, selectCountryByUser } from 'ducks/scheduling';
import HardcodedDescriptors, {
  TestConsultation,
  ReviewConsultation,
  FollowUpConsultation,
  CurbsideConsultation,
  ExpeditedResultsReviewConsultation,
  AllHardcodedConsultations,
  getHardcodedDescriptorByType,
} from 'constants/ServiceTypes';
import { GetStateTimezone } from 'services/geo';
import schedulingService from 'services/api/schedulingService';
import { formatPrice } from 'services/utils';
import SelectServicePanel from './SelectServicePanelV2';
import './SelectServicePage.scss';
import authService from 'services/api/authService';
import gaTrack, { GA_TR_SCHEDULING_SELECT_SERVICE_PAGE } from '../../../../../services/gaTrack';
import {
  setDefaultStateAndTimezone,
  setDefaultVseeSpecialty,
  selectProviderByUser,
  selectModalityByUser,
} from 'ducks/scheduling';

const selectHint = 'Choose this option if you are considering genetic testing';
const SelectServicePage = ({
  history,
  consultationTypes,
  appliedCoupon,
  hiddenCouponApplied,
  purchasedServicePackages,
  isMobile,
  scheduling,
  dispatch,
  gotoCalendar,
  user,
  shippingAddress,
  homeAddress,
  setCouponCode,
  clearCoupon,
  insuranceEnabled,
  toPreviousStep,
}) => {
  const [selected, setSelected] = useState(null);
  const [preSelected, setPreSelected] = useState(null);
  const [isSpecialtySelected, setIsSpecialitySelected] = useState(false);
  let purchaseClicked = undefined;

  useEffect(() => {
    gaTrack(GA_TR_SCHEDULING_SELECT_SERVICE_PAGE);
    window.scrollTo(0, 0);
    const { calendarCountry } = scheduling;

    const { OutreachAppointment, isOutreachAppointmentFlow } =
      schedulingService.getOutreachAppointmentData();

    const allowedCountries = _.get(user, ['allowedCountries'], ['US']);
    if (!calendarCountry && allowedCountries && allowedCountries.length > 0) {
      dispatch(selectCountryByUser(allowedCountries[0]));
    }

    if (consultationTypes.length > 1) {
      dispatch(clearSelectedService());
    }

    if (consultationTypes.length === 1) {
      const type = _.get(consultationTypes[0], 'type', '');
      setPreSelected(type);
      setSelected(type);
    }

    dispatch(selectModalityByUser(false));

    const isFollowUpConsultation = _.find(consultationTypes, (obj) => {
      return obj.type === FollowUpConsultation;
    });
    if (isOutreachAppointmentFlow && isFollowUpConsultation) {
      const countryIsNonUS = _.get(user, ['countryIsNonUS'], false);
      const address = countryIsNonUS ? homeAddress : shippingAddress;
      if (address && !_.isEmpty(address)) {
        const timezone = GetStateTimezone(address.addressState);
        dispatch(
          setDefaultStateAndTimezone(address.addressCountry, address.addressState, timezone)
        );
      }
      if (!_.isNil(OutreachAppointment)) {
        dispatch(setDefaultVseeSpecialty(OutreachAppointment.vsee_specialty));
        dispatch(selectProviderByUser(OutreachAppointment.provider.id));
      } else {
        dispatch(setDefaultVseeSpecialty(user.vsee_specialty));
      }
      const type = getHardcodedDescriptorByType(FollowUpConsultation);
      const urlParameter = type && type.urlParameter;
      gotoCalendar(urlParameter);
    }

    const preSelected = authService.getPreselectedService();
    if (preSelected) {
      const testType =
        (HardcodedDescriptors[preSelected] && HardcodedDescriptors[preSelected].type) ||
        preSelected;
      setSelected(testType);
      setPreSelected(testType);
    }
    const partnerSpecialties = user?.partnerSpecialties || [];
    if (consultationTypes.length == 1 && partnerSpecialties.length == 1) {
      const specialityName = _.get(partnerSpecialties, ['0', 'name'], '');
      dispatch(setDefaultVseeSpecialty(specialityName));
      const consultationType = _.get(consultationTypes, ['0', 'type'], '');
      const type = getHardcodedDescriptorByType(consultationType);
      if(type){
        const urlParameter = type && type.urlParameter;
        gotoCalendar(urlParameter);
      }
      else
        gotoCalendar(consultationType);
      
    }

    return () => {
      if (purchaseClicked) {
        if (appliedCoupon && appliedCoupon.code) setCouponCode(appliedCoupon.code, true);
      } else clearCoupon(true);
    };
  }, []);

  const getPath = (name) => {
    const param = (HardcodedDescriptors[name] && HardcodedDescriptors[name].urlParameter) || name;
    return `/scheduling/${param}/calendar`;
  };

  const onPurchaseClick = (path) => {
    purchaseClicked = true;
    history.push(path);
  };

  const onFormSubmit = () => {
    const selectedConsultation = consultationTypes.find((a) => a.type == selected);
    if (selectedConsultation) {
      console.log(selectedConsultation);
      const type = getHardcodedDescriptorByType(selectedConsultation.type);
      if(type)
        onPurchaseClick(getPath(type?.urlParameter));
      else 
      onPurchaseClick(getPath(selectedConsultation?.type));
    }
  };

  const getPrice = (name) => {
    const type = HardcodedDescriptors[name].type || name;
    const getProductPrice = (product) => {
      const regularPrice = formatPrice(product.price);
      const couponItem = findCouponItem({
        appliedCoupon,
        selectedServiceDescriptor: { isPackage: false },
        selectedProduct: product,
        purchasedServicePackages: purchasedServicePackages,
      });

      if (!couponItem) {
        const prepaid = false;
        return {
          price: prepaid ? formatPrice(prepaid.payment.totalCharge) : regularPrice,
        };
      }

      const couponPrice = formatPrice(couponItem.price);
      return hiddenCouponApplied && !isNaN(couponItem.price)
        ? { price: couponPrice }
        : { price: couponPrice, oldPrice: regularPrice };
    };

    const consultation = consultationTypes.find((t) => t.type === type);
    return consultation ? getProductPrice(consultation) : { price: null, oldPrice: null };
  };

  const getHardcodedConsultation = (name) => {
    const type = HardcodedDescriptors[name].type;
    if (isMobile && selected && selected != type) {
      return null;
    }
    return consultationTypes.find((t) => t.type === type);
  };

  const getExtraConsulations = () => {
    const ignoredTypes = AllHardcodedConsultations.map((name) => HardcodedDescriptors[name].type);
    if (isMobile && selected) {
      return consultationTypes.filter((t) => !ignoredTypes.includes(t.type) && t.type == selected);
    }
    return consultationTypes.filter((t) => !ignoredTypes.includes(t.type));
  };

  const renderPageContent = () => {
    const { calendarState, calendarTimezone, calendarCountry } = scheduling;
    const disabled = !calendarState || !calendarTimezone || !calendarCountry;

    const prices = {
      curbside_chat15: getPrice(CurbsideConsultation),
      test: getPrice(TestConsultation),
      review: getPrice(ReviewConsultation),
      follow_up: getPrice(FollowUpConsultation),
      expedited_results_review: getPrice(ExpeditedResultsReviewConsultation),
    };
    const proceedWithFollowUp = false;

    const curbsideConsultation = getHardcodedConsultation(CurbsideConsultation);
    const testConsultation = getHardcodedConsultation(TestConsultation);
    const reviewConsultation = getHardcodedConsultation(ReviewConsultation);
    const followUpConsultation = getHardcodedConsultation(FollowUpConsultation);
    const expeditedResultsReviewConsultation = getHardcodedConsultation(
      ExpeditedResultsReviewConsultation
    );
    const extraConsultations = getExtraConsulations();
    const hasSpecialtySelected = isSpecialtySelected == selected;
    // TODO: Encapsulate service properties accessing into SelectServicePanel
    return (
      <div>
        <div className={classnames('selector-panels-container v2', { 'is-mobile': isMobile })}>
          <div>
            {!_.isEmpty(extraConsultations) && (
              <div className="extra-consultations-container">
                <h2 className="panels-group-title mgt2per">Select Visit Details</h2>
                <div className="panels-group-description display-flex">
                  <div className="cdt-icon-contain" />
                  <div className="panels-group-description-header">
                    <h4>Select, discuss, and order your genetic health screen</h4>
                  </div>
                  <div className="panels-group-description-content">
                    Schedule a consultation to review and assess your personal and family medical
                    history, answer questions regarding your selected genetic health screen, and
                    authorize your genetic test. Each selection includes cost of: consultation, test
                    authorization, and genetic test.
                  </div>
                </div>
                {extraConsultations.map((extraConsultation) => (
                  <SelectServicePanel
                    key={extraConsultation.name}
                    header={extraConsultation.name}
                    type={extraConsultation.type}
                    iconClassName=""
                    description={extraConsultation.description}
                    price={extraConsultation.price}
                    path={getPath(extraConsultation.type)}
                    disabled={proceedWithFollowUp}
                    onPurchaseClick={onPurchaseClick}
                    insuranceEnabled={insuranceEnabled}
                    setSelected={setSelected}
                    selected={selected}
                    preSelected={preSelected}
                    zeroPriceText={null}
                    isPredefined={extraConsultation.isPredefined}
                    isMobile={isMobile}
                    setIsSpecialitySelected={setIsSpecialitySelected}
                    isSpecialtySelected={isSpecialtySelected}
                  />
                ))}
              </div>
            )}
            {(testConsultation ||
              reviewConsultation ||
              curbsideConsultation ||
              expeditedResultsReviewConsultation) && (
              <div>
                <h2 className="panels-group-title">Select Visit Details</h2>
                {curbsideConsultation && (
                  <SelectServicePanel
                    header={curbsideConsultation.description}
                    type={curbsideConsultation.type}
                    iconClassName="icon-testing-guidance"
                    description={curbsideConsultation.detailedDescription}
                    selectHint={selectHint}
                    price={prices[CurbsideConsultation].price}
                    oldPrice={prices[CurbsideConsultation].oldPrice}
                    path={getPath(CurbsideConsultation)}
                    disabled={proceedWithFollowUp}
                    onPurchaseClick={onPurchaseClick}
                    insuranceEnabled={insuranceEnabled}
                    setSelected={setSelected}
                    selected={selected}
                    preSelected={preSelected}
                    zeroPriceText={null}
                    isPredefined={curbsideConsultation.isPredefined}
                    isMobile={isMobile}
                    setIsSpecialitySelected={setIsSpecialitySelected}
                    isSpecialtySelected={isSpecialtySelected}
                  />
                )}
                {testConsultation && (
                  <SelectServicePanel
                    header={testConsultation.description}
                    type={testConsultation.type}
                    iconClassName="icon-testing-guidance"
                    description={testConsultation.detailedDescription}
                    selectHint={selectHint}
                    price={prices[TestConsultation].price}
                    oldPrice={prices[TestConsultation].oldPrice}
                    path={getPath(TestConsultation)}
                    disabled={proceedWithFollowUp}
                    onPurchaseClick={onPurchaseClick}
                    insuranceEnabled={insuranceEnabled}
                    setSelected={setSelected}
                    selected={selected}
                    preSelected={preSelected}
                    zeroPriceText={null}
                    isPredefined={testConsultation.isPredefined}
                    isMobile={isMobile}
                    setIsSpecialitySelected={setIsSpecialitySelected}
                    isSpecialtySelected={isSpecialtySelected}
                  />
                )}
                {reviewConsultation && (
                  <SelectServicePanel
                    header={reviewConsultation.description}
                    type={reviewConsultation.type}
                    iconClassName="icon-results-review"
                    description={reviewConsultation.detailedDescription}
                    selectHint={selectHint}
                    price={prices[ReviewConsultation].price}
                    oldPrice={prices[ReviewConsultation].oldPrice}
                    path={getPath(ReviewConsultation)}
                    onPurchaseClick={onPurchaseClick}
                    insuranceEnabled={insuranceEnabled}
                    setSelected={setSelected}
                    selected={selected}
                    preSelected={preSelected}
                    zeroPriceText={null}
                    isPredefined={reviewConsultation.isPredefined}
                    isMobile={isMobile}
                    setIsSpecialitySelected={setIsSpecialitySelected}
                    isSpecialtySelected={isSpecialtySelected}
                  />
                )}
                {expeditedResultsReviewConsultation && (
                  <SelectServicePanel
                    header={expeditedResultsReviewConsultation.description}
                    type={expeditedResultsReviewConsultation.type}
                    iconClassName="icon-results-review"
                    description={expeditedResultsReviewConsultation.detailedDescription}
                    selectHint={selectHint}
                    price={prices[ExpeditedResultsReviewConsultation].price}
                    oldPrice={prices[ExpeditedResultsReviewConsultation].oldPrice}
                    path={getPath(ExpeditedResultsReviewConsultation)}
                    onPurchaseClick={onPurchaseClick}
                    insuranceEnabled={insuranceEnabled}
                    setSelected={setSelected}
                    selected={selected}
                    preSelected={preSelected}
                    zeroPriceText={null}
                    isPredefined={expeditedResultsReviewConsultation.isPredefined}
                    isMobile={isMobile}
                    setIsSpecialitySelected={setIsSpecialitySelected}
                    isSpecialtySelected={isSpecialtySelected}
                  />
                )}
              </div>
            )}
            {followUpConsultation && (
              <div>
                <h2 className="panels-group-title">Follow up</h2>
                <SelectServicePanel
                  header={followUpConsultation.description}
                  type={followUpConsultation.type}
                  iconClassName="icon-follow-up"
                  description={followUpConsultation.detailedDescription}
                  price={prices[FollowUpConsultation].price}
                  oldPrice={prices[FollowUpConsultation].oldPrice}
                  path={getPath(FollowUpConsultation)}
                  onPurchaseClick={onPurchaseClick}
                  insuranceEnabled={insuranceEnabled}
                  setSelected={setSelected}
                  selected={selected}
                  preSelected={preSelected}
                  zeroPriceText={
                    _.get(user, 'affiliation') === 'Northshore'
                      ? '*Pricing varies based on insurance, but in most instances this would be a fully covered benefit.'
                      : null
                  }
                  isPredefined={followUpConsultation.isPredefined}
                  isMobile={isMobile}
                  setIsSpecialitySelected={setIsSpecialitySelected}
                  isSpecialtySelected={isSpecialtySelected}
                />
              </div>
            )}
          </div>
          {!isMobile ? (
            <div className="action-buttons">
              {toPreviousStep && (
                <button onClick={toPreviousStep} className="btn-back">
                  Back
                </button>
              )}
              <button
                onClick={onFormSubmit}
                disabled={disabled || !hasSpecialtySelected}
                className="btn-next"
              >
                {'Next'}
              </button>
            </div>
          ) : toPreviousStep && !selected ? (
            <div className="buttons">
              <Button className="button-back-to-address v2" onClick={toPreviousStep}>
                Back
              </Button>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="scp-schedule-wizard scp-select-service-page full-height">
      {scheduling.isSchedulingLoaded && renderPageContent()}
    </div>
  );
};

SelectServicePage.propTypes = {
  user: PropTypes.object,
  consultationTypes: PropTypes.array.isRequired,
  setCouponCode: PropTypes.func.isRequired,
  clearCoupon: PropTypes.func.isRequired,
  scheduling: PropTypes.object.isRequired,
  appliedCoupon: PropTypes.object,
  couponError: PropTypes.object,
  isCheckingCouponCode: PropTypes.bool,
  hiddenCouponApplied: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  redirectAddress: PropTypes.string.isRequired,
  insuranceEnabled: PropTypes.bool,
  zeroPriceText: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.user.me,
  scheduling: state.scheduling,
  purchasedServicePackages: _.get(state.scheduling, 'purchasedServicePackages', []),
  consultationTypes: state.scheduling.consultationTypes,
  redirectAddress: '/patient',
});

export default connect(mapStateToProps)(SelectServicePage);
